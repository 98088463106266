import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { Button, Grid } from "@material-ui/core";

const useStyles = makeStyles({
  group: {
    width: "100%"
  },
  item: {
    background: "white",
    textAlign: "left",
    justifyContent: "flex-start",
    color: "#000000",
    fontWeight: 400,
    margin: 10,
    border: "1px solid #ccc",
    borderRadius: 20,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#CCC"
    },
    "&:not(:first-child)": {
      borderRadius: 20
    },
    "&:first-child": {
      borderRadius: 20
    }
  },
  confirmButton: {
    borderRadius: 20
  },
  center: {
    textAlign: "center"
  },
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  }
});

const ButtonPanel = props => {
  const classes = useStyles();
  const [selected, setSelected] = useState(props.selected);

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  const handleSelection = (event, selected) => {
    setSelected(selected);
  };

  const goBack = () => {
    props.setNextQuestion(-1);
  };

  const confirmSelection = (
    { question, user, setNextQuestion },
    nextIndex,
    finished
  ) => {
    if (selected) {
      question.selected = selected;
      //TODO Save LocalStorage
      setNextQuestion(nextIndex);
      setSelected("");
    }
    if (finished) {
      //TODO
    }
  };

  return (
    <Grid container alignContent="center" alignItems="center">
      <Grid item xs={12}>
        <ToggleButtonGroup
          className={classes.group}
          value={selected}
          exclusive
          onChange={handleSelection}
          orientation="vertical"
          aria-label="Alternativas"
        >
          <ToggleButton
            value="A"
            aria-label="Alternativa A"
            className={classes.item}
          >
            {props.question.altA}
          </ToggleButton>
          <ToggleButton
            value="b"
            aria-label="Alternativa B"
            className={classes.item}
          >
            {props.question.altB}
          </ToggleButton>
          <ToggleButton
            value="c"
            aria-label="Alternativa C"
            className={classes.item}
          >
            {props.question.altC}
          </ToggleButton>
          <ToggleButton
            value="d"
            aria-label="Alternativa D"
            className={classes.item}
          >
            {props.question.altD}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={3} className={classes.left}>
        {props.index > 0 && (
          <Button
            className={classes.confirmButton}
            variant="contained"
            color="secondary"
            onClick={() => goBack()}
          >
            Anterior
          </Button>
        )}
      </Grid>
      <Grid item xs={6} className={classes.center}>
        &nbsp;
      </Grid>
      <Grid item xs={3} className={classes.right}>
        {props.index < props.total - 1 && (
          <Button
            className={classes.confirmButton}
            variant="contained"
            color="secondary"
            onClick={() => confirmSelection(props, 1)}
          >
            Próxima
          </Button>
        )}
        {props.index === props.total - 1 && (
          <Button
            className={classes.confirmButton}
            variant="contained"
            color="secondary"
            onClick={() => confirmSelection(props, 1, true)}
          >
            Entregar respostas
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default ButtonPanel;

import React from "react";

import FacebookBoxIcon from "mdi-material-ui/Facebook";
import GoogleIcon from "mdi-material-ui/Google";

const authProviders = [
  {
    providerId: "facebook.com",
    color: "#3c5a99",
    icon: <FacebookBoxIcon />,
    name: "Facebook"
  },
  {
    providerId: "google.com",
    color: "#4285f4",
    icon: <GoogleIcon />,
    name: "Google"
  }
];

export default authProviders;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";

import httpClient from "../../services/httpClient";

import Question from "../SimuladoQuestion";

const useStyles = makeStyles(theme => ({
  header: {
    marginTop: 20
  },
  redBox: {
    backgroundColor: "#EB5757",
    width: "50%",
    marginBottom: 50,
    borderRadius: 20,
    padding: 10
  },
  title: {
    fontSize: 24
  },
  rightBorder: {
    borderRight: "2px solid #333333"
  },
  realtimeData: {
    textAlign: "center",
    color: "#FAFAFA",
    heigth: "100%"
  },
  number: {
    fontWeight: "bold",
    fontSize: "42px",
    margin: 0,
    padding: 0
  }
}));
const SimuladoQuestionPanel = props => {
  const classes = useStyles();
  const { user, mock, setMockTest } = props;
  const totalTime = parseInt(mock.timeToComplete, 10) * 60;
  const [counter, setCounter] = useState(totalTime);
  const [questionsLeft, setQuestionsLeft] = useState(mock.questions.length);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(
    mock.questions[currentQuestionIndex]
  );

  const currentTime = () => {
    return new Date(counter * 1000).toISOString().substr(11, 8);
  };

  const finish = async () => {
    const finished = {
      finished: true,
      finishTime: new Date(),
      remainingSeconds: counter,
      remainingTime: counter === 0 ? 0 : currentTime()
    };
    httpClient.patchMockTestProgress(mock.progressId, finished).then(() =>
      setMockTest({
        ...mock,
        ...finished
      })
    );
  };

  useEffect(() => {
    if (counter > 0) {
      const timer = setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
    finish();
  }, [counter]);

  useEffect(() => {
    setCurrentQuestion(mock.questions[currentQuestionIndex]);
  }, [currentQuestionIndex]);

  // const updateElapsedTime = () => {
  //   const newCurrentQuestion = { ...mock.questions[currentQuestionIndex] };
  //   newCurrentQuestion.end = new Date();
  //   newCurrentQuestion.elapsedTime = Math.floor(
  //     Math.abs(
  //       (newCurrentQuestion.start.getTime() -
  //         newCurrentQuestion.end.getTime()) /
  //         1000
  //     )
  //   );
  //   setCurrentQuestion(newCurrentQuestion);
  // };

  const updateDb = async finished =>
    new Promise(resolve => {
      const data = {
        id: mock.id,
        questionIndex: currentQuestionIndex,
        questionId: currentQuestion._id,
        correct: currentQuestion.correta.toLowerCase(),
        selected: currentQuestion.selected.toLowerCase(),
        user: {
          userMail: user.email,
          userId: user.uid
        }
      };
      httpClient
        .patchMockTestProgress(mock.progressId, data)
        .then(() => resolve());
    });

  const setNextQuestion = async positionToAdd => {
    if (positionToAdd > 0) {
      await updateDb();
    }
    const newIndex = currentQuestionIndex + positionToAdd;
    setQuestionsLeft(mock.questions.filter(q => !q.selected).length);
    const finished = newIndex >= mock.questions.length;
    if (!finished) {
      setCurrentQuestionIndex(newIndex);
    } else {
      finish();
    }
  };

  // const handleClose = prop => {
  //   if (prop === "next") {
  //     setBackdropState(!backdropState);
  //     // getNextQuestion();
  //   } else {
  //     setBackdropState(!backdropState);
  //     // changeMode("");
  //   }
  // };

  const Header = () => (
    <>
      <Grid container className={classes.header}>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.title}>
            {mock.title}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.redBox}>
          <Grid container className={classes.realtimeData}>
            <Grid item xs={5} className={classes.rightBorder}>
              <p className={classes.number}>{questionsLeft}</p>
            </Grid>
            <Grid item xs={7}>
              <p className={classes.number}>{currentTime()}</p>
            </Grid>
            <Grid item xs={5} className={classes.rightBorder}>
              questões restantes
            </Grid>
            <Grid item xs={7}>
              tempo restante
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
  return (
    <>
      <Container xs={12} md={6} lg={3}>
        <Header />
        <Question
          user={props.user}
          index={currentQuestionIndex}
          total={mock.questions.length}
          classes={props.classes}
          value={currentQuestion}
          setNextQuestion={setNextQuestion}
        />
      </Container>
    </>
  );
};

export default SimuladoQuestionPanel;

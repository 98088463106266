import React from 'react';
import { useHistory } from 'react-router';

import { Grid, Typography } from '@material-ui/core';
import ModeChoiceButton from '../ModeChoiceButton';
import iconArcade from '../../assets/icon-arcade.svg';
import iconTrainning from '../../assets/icon-treinamento.svg';
import iconSimulado from '../../assets/icon-simulado.png';
import iconBizu from '../../assets/icon-bizu.png';
import iconVideo from '../../assets/icon-video.svg';
import iconWiki from '../../assets/icon-wiki.png';
import RankingButton from '../RankingButton';
import ModeChoiceButtonPanelinha from '../ModeChoiceButton/ModeChoicePanelinha';

import { makeStyles } from '@material-ui/core/styles';


/*

<Grid item xs={12} className={classes.clickable}>
          {!panelinha && (
            <ModeChoiceButtonPanelinha
              title="Modo Simulado"
              description="Realize simulados para se preparar melhor para o dia da prova."
              icon={iconSimulado}
              onClick={() => {
                amplitude.logEvent('Menu Simulado Click - Comprar');
               // window.open('https://panelinha2022.nery.net.br');
              }}
            />
          )}
          {panelinha && (
            <ModeChoiceButton
              title="Modo Simulado"
              description="Realize simulados para se preparar melhor para o dia da prova."
              icon={iconSimulado}
              onClick={() => {
                amplitude.logEvent('Menu Simulado Click - Panelinha');
                props.changeMode('simulado');
                history.push({
                  pathname: '/simulado'
                });
              }}
            />
          )}
        </Grid>


        */

const useStyles = makeStyles(theme => ({
  clickable: {
    cursor: 'pointer'
  }
}));

const Menu = props => {
  const { panelinha, amplitude } = props;
  const classes = useStyles();
  const history = useHistory();
  return (
    <div
      style={{
        flexGrow: 1,
        maxWidth: 470,
        margin: '15px auto'
      }}
    >
      <RankingButton
        panelinha={panelinha}
        onClick={() => {
          amplitude.logEvent(
            `Menu Ranking Click - ${panelinha ? 'Panelinha' : 'Comprar'}`
          );
          if (!panelinha) {
           //  window.open("https://panelinha2022.nery.net.br");
          } else {
            history.push({
              pathname: '/ranking'
            });
          }
        }}
      />
      <Typography
        style={{ margin: '20px 15px', fontSize: 14, fontWeight: 'bold' }}
      >
        Escolha a melhor forma para você estudar agora:
      </Typography>
      <Grid container direction="column" spacing={0}>
        <Grid item xs={12} className={classes.clickable}>
          <ModeChoiceButton
            title="Modo Arcade"
            description="Teste seus conhecimentos e responda questõs de todos os temas no Caixa Preta."
            icon={iconArcade}
            onClick={() => {
              amplitude.logEvent(
                `Menu Arcade Click - ${
                  panelinha ? 'Panelinha' : 'Acesso Livre'
                }`
              );
              props.changeMode('arcade');
              history.push({
                pathname: '/arcade'
              });
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.clickable}>
          {!panelinha && (
            <ModeChoiceButtonPanelinha
              title="Modo Treinamento"
              description="Escolha um tema no Caixa Preta e estude tudo que quiser sobre ele."
              icon={iconTrainning}
              onClick={() => {
                amplitude.logEvent('Menu Treinamento Click - Comprar');
                // window.open('https://panelinha2022.nery.net.br');
              }}
            />
          )}
          {panelinha && (
            <ModeChoiceButton
              title="Modo Treinamento"
              description="Escolha um tema no Caixa Preta e estude tudo que quiser sobre ele."
              icon={iconTrainning}
              onClick={() => {
                amplitude.logEvent('Menu Treinamento Click - Panelinha');
                props.changeMode('training');
                history.push({
                  pathname: '/training'
                });
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} className={classes.clickable}>
          {!panelinha && (
            <ModeChoiceButtonPanelinha
              title="Bizu"
              description="Materiais exclusivos, resumos em forma de mapa mental direcionados ao que você precisa estudar."
              icon={iconBizu}
              onClick={() => {
                amplitude.logEvent('Menu Bizu Click - Comprar');
               // window.open('https://panelinha2022.nery.net.br');
              }}
            />
          )}
          {panelinha && (
            <ModeChoiceButton
              title="Bizu"
              description="Materiais exclusivos, resumos em forma de mapa mental direcionados ao que você precisa estudar."
              icon={iconBizu}
              onClick={() => {
                amplitude.logEvent('Menu Bizu Click - Panelinha');
                props.changeMode('bizu');
                history.push({
                  pathname: '/bizu'
                });
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} className={classes.clickable}>
          <ModeChoiceButton
            title="Neryflix"
            description="Maratone todas as aulas que já foram dadas."
            icon={iconVideo}
            onClick={() => {
              amplitude.logEvent('Menu Neryflix Click');
              props.changeMode('videos');
              history.push({
                pathname: '/videos'
              });
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.clickable}>
          <ModeChoiceButton
            title="Nerypedia"
            description="Toda a legislação que você precisa para se dar bem!"
            icon={iconWiki}
            onClick={() => {
              amplitude.logEvent('Menu Nerypedia Click');
              window.open(
                'https://drive.google.com/drive/folders/1WQyB9JMMmxmT6jPetDFwqUT-sAmJqGEn?usp=sharing'
              );
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Menu;

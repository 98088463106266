import * as firebase from "firebase/app";

import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/performance";

// firebase.initializeApp({
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
// });

var firebaseConfig = {
  apiKey: "AIzaSyBhJvGoPU5kpoSIr81eAwUTLiRlefr4AGo",
  authDomain: "caixa-preta-nery.firebaseapp.com",
  databaseURL: "https://caixa-preta-nery.firebaseio.com",
  projectId: "caixa-preta-nery",
  storageBucket: "caixa-preta-nery.appspot.com",
  messagingSenderId: "827237413167",
  appId: "1:827237413167:web:a24c8c110241eb05cd31da",
  measurementId: "G-7GWX2TVBGV"
};

firebase.initializeApp(firebaseConfig);

export default firebase;
export const analytics = firebase.analytics();
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const performance = firebase.performance();

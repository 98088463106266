import React from "react";

import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import iconRanking from "../../assets/icon-ranking.svg";
import pan from "../../assets/pan.svg";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#EB5757",
    color: "#333333",
    minHeight: 64,
    borderRadius: 20,
    boxShadow: "0px 4px 5px 0px rgba(0,0,0,0.25)",
    MozBoxShadow: "0px 4px 5px 0px rgba(0,0,0,0.25)",
    WebkitBoxShadow: "0px 4px 5px 0px rgba(0,0,0,0.25)",
    margin: "24px 16px"
  },
  icons: {
    height: 64,
    width: 64,
    margin: "10px 10px 0 10px"
  },
  title: {
    fontWeight: "bold",
    fontSize: 18,
    marginTop: 8,
    padding: "0 15px",
    color: "#FAFAFA"
  },
  description: {
    color: "#FAFAFA",
    fontSize: 14,
    marginTop: 8,
    padding: "0 15px"
  },
  tarja: {
    backgroundColor: "yellow",
    color: "#333333",
    padding: 5,
    textAlign: "center",
    letterSpacing: 5
  },
  pan: {
    width: 28,
    height: 28
  },
  panelinhaText: {
    color: "#b34c4c"
  }
}));

const RankingButton = props => {
  const { panelinha } = props;
  const classes = useStyles(props);

  return (
    <Box className={classes.root} onClick={props.onClick}>
      {!panelinha && (
        <div className={classes.tarja}>
          EXCLUSIVO PARA A PANELINHA{" "}
          <img className={classes.pan} src={pan} alt="Panelinha" />
        </div>
      )}
      <Grid container alignContent="center" alignItems="center">
        <Grid item xs={1}>
          &nbsp;
        </Grid>
        <Grid item xs={3}>
          <img
            className={classes.icons}
            src={iconRanking}
            alt="Ranking"
            title="Ranking"
          />
        </Grid>
        <Grid item xs={8}>
          <Box>
            <Typography
              className={
                !panelinha
                  ? `${classes.title} ${classes.panelinhaText}`
                  : classes.title
              }
            >
              Ranking
            </Typography>
            <Typography
              className={
                !panelinha
                  ? `${classes.description} ${classes.panelinhaText}`
                  : classes.description
              }
            >
              Veja sua posição no Ranking.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RankingButton;

import React from "react";
import ReactPlayer from "react-player";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNextRounded";
import httpClient from "../../services/httpClient";

const useStyles = makeStyles({
  playerWrapper: {
    position: "relative",
    margin: "0 auto",
    "@media (min-width:780px)": {
      // eslint-disable-line no-useless-computed-key
      paddingTop: "45%",
      maxWidth: 700
    },
    "@media (max-width:780px)": {
      // eslint-disable-line no-useless-computed-key
      paddingTop: "177.77%"
    }
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  }
});

function VideoPlayer(props) {
  const classes = useStyles();
  httpClient
    .registerWatch({ video: props.video, user: props.user.email })
    .then(response => {});

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" onClick={() => props.resetVideo()}>
          Video-aulas
        </Link>
        <Typography color="textPrimary">{props.video.title}</Typography>
      </Breadcrumbs>

      <div className={classes.playerWrapper}>
        <ReactPlayer
          className={classes.reactPlayer}
          url={props.video.url}
          config={{ youtube: { playerVars: { disablekb: 1 } } }}
          width="100%"
          height="100%"
        />
      </div>
    </>
  );
}

export default VideoPlayer;

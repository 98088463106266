import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  LinearProgress
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 660,
    margin: '0 auto'
  },
  list: {
    backgroundColor: '#ffffff',
    color: '#333333',
    borderRadius: 20,
    marginLeft: 8,
    marginRight: 8
  },
  title: {
    margin: '20px 15px',
    fontSize: 18,
    fontWeight: 'bold'
  },
  listItem: {
    background: '#CCCCCC'
  },
  name: {
    paddingLeft: 15,
    fontSize: 10,
    '& .MuiTypography-colorTextSecondary': {
      color: 'gray',
      fontSize: 11
    }
  },
  icon: {
    color: 'red'
  },
  iconDone: {
    color: 'gray'
  }
}));

const SimuladoList = props => {
  const classes = useStyles();

  const { mocks, setMock } = props;

  const TagLinks = () => {
    if (mocks && mocks.length) {
      return mocks.map(tag => (
        <>
          <ListItem button key={tag._id}>
            <ListItemText
              className={classes.name}
              primary={tag.title}
              secondary={tag.done && 'Simulado realizado há menos de 24 horas.'}
              onClick={() => {
                if (!tag.done) {
                  setMock(tag);
                }
              }}
            />
            <ListItemIcon>
              {tag.done && <EventBusyIcon className={classes.iconDone} />}
              {!tag.done && <ArrowForwardIcon className={classes.icon} />}
            </ListItemIcon>
          </ListItem>
          <Divider className={classes.listItem} color="#333333" />
        </>
      ));
    }
    return <></>;
  };

  const Title = () => {
    if (!mocks.length) {
      return (
        <>
          <Typography className={classes.title}>
            Carregando itens disponíveis
          </Typography>
          <LinearProgress variant="query" />
        </>
      );
    }
    return (
      <Typography className={classes.title}>SIMULADOS DISPONÍVEIS</Typography>
    );
  };

  return (
    <div className={classes.root} key="containerMockTest">
      <Title key="titleMockTest" />
      <List className={classes.list} component="nav" key="tagsListComponent">
        <TagLinks key="tagLinksFromApi" />
      </List>
      <Divider />
    </div>
  );
};

export default SimuladoList;

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';

import HomeContent from '../HomeContent';
import AuthContent from '../AuthContent';
import AdminContent from '../AdminContent';
import NotFoundContent from '../NotFoundContent';
import BizuList from '../../pages/Bizu';
import Simulado from '../../pages/Simulado';
import Ranking from '../../pages/Ranking';
import Comments from '../../pages/Comments';
import Notifications from '../../pages/Notifications';
import ThanksPage from '../../pages/Thanks';
import MenuArcade from '../MenuArcade';
import QuestionPanel from '../QuestionPanel';
import TrainingTags from '../TrainingTags';
import VideoGallery from '../VideoGallery';
import VideoPlayer from '../VideoPlayer';

class Router extends Component {
  render() {
    // Properties
    const { user } = this.props;

    // Functions
    const { openSnackbar, setNotificationsCount } = this.props;

    //const panelinha = user && user.panelinha; replace back
    const panelinha = true;

    return (
      <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
        <Switch>
          <Route path="/" exact>
            <HomeContent
              user={user}
              openSnackbar={openSnackbar}
              changeMode={this.props.changeMode}
              changeVideo={this.props.changeVideo}
              currentVideo={this.props.currentVideo}
              changeTrainingTag={this.props.changeTrainingTag}
              changeSimuladoTag={this.props.changeSimuladoTag}
              changeArcadeTag={this.props.changeArcadeTag}
              mode={this.props.mode}
              trainingTag={this.props.trainingTag}
              arcadeTag={this.props.arcadeTag}
              simuladoTag={this.props.simuladoTag}
              onSignUpClick={this.props.onSignUpClick}
              dialogProps={this.props.dialogProps}
              panelinha={panelinha}
              amplitude={this.props.amplitude}
              setNotificationsCount={this.props.setNotificationsCount}
            />
          </Route>
          <Route path="/arcade" exact>
            {user ? (
              <MenuArcade
                changeArcadeTag={this.props.changeArcadeTag}
                arcadeTag={this.props.arcadeTag}
                user={user}
              />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/arcade/:tag" exact>
            {user ? (
              <QuestionPanel
                user={user}
                mode={this.props.mode}
                changeMode={this.props.changeMode}
              />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/training" exact>
            {user && panelinha ? (
              <TrainingTags
                changeTag={this.props.changeTrainingTag}
                user={user}
                panelinha={panelinha}
                amplitude={this.props.amplitude}
              />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/training/:tag+" exact>
            {user && panelinha ? (
              <QuestionPanel
                user={user}
                mode={this.props.mode}
                changeMode={this.props.changeMode}
                panelinha={panelinha}
              />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/trainingMultiple/:tags+" exact>
            {user && panelinha ? (
              <QuestionPanel
                user={user}
                mode={this.props.mode}
                changeMode={this.props.changeMode}
                panelinha={panelinha}
              />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/question/:questionId" exact>
            {user && panelinha ? (
              <QuestionPanel
                user={user}
                mode={this.props.mode}
                changeMode={this.props.changeMode}
                panelinha={panelinha}
              />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/bizu" exact>
            {user && panelinha ? (
              <BizuList
                user={user}
                panelinha={panelinha}
                openSettings={this.props.openSettings}
              />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/bizu/:bizuId+" exact>
            {user && panelinha ? (
              <BizuList
                user={user}
                panelinha={panelinha}
                openSettings={this.props.openSettings}
              />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/videos" exact>
            {user ? (
              <VideoGallery
                user={user}
                panelinha={panelinha}
                loadVideo={this.props.changeVideo}
              />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/videos/play" exact>
            {user ? (
              <VideoPlayer
                video={this.props.currentVideo}
                user={user}
                panelinha={panelinha}
                resetVideo={() => this.props.changeVideo({})}
              />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/videos/play/:videoTitle" exact>
            {user ? (
              <VideoGallery
                user={user}
                panelinha={panelinha}
                loadVideo={this.props.changeVideo}
              />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/simulado" exact>
            {panelinha ? <Simulado user={user} /> : <Redirect to="/" />}
          </Route>
          {/* <Route path="/simulado/result" exact>
            {panelinha ? <SimuladoResult mock={mock} /> : <Redirect to="/" />}
          </Route> */}
          <Route path="/ranking" exact>
            {panelinha ? <Ranking user={user} /> : <Redirect to="/" />}
          </Route>
          <Route path="/comments" exact>
            {panelinha ? <Comments user={user} /> : <Redirect to="/" />}
          </Route>
          <Route path="/notifications" exact>
            {user ? <Notifications user={user} 
              amplitude={this.props.amplitude}
              setNotificationsCount={setNotificationsCount} /> : <Redirect to="/" />}
          </Route>
          <Route path="/inscricao-completa" exact>
            {<ThanksPage user={user} />}
          </Route>
          <Route path="/auth">
            {user ? <AuthContent /> : <Redirect to="/" />}
          </Route>

          <Route path="/admin">
            {user && user.admin ? ( //roles.includes("admin") ? (
              <AdminContent user={user} />
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route>
            <NotFoundContent />
          </Route>
        </Switch>
      </BrowserRouter>
    );
  }
}

Router.propTypes = {
  // Properties
  user: PropTypes.object,
  roles: PropTypes.array.isRequired,

  // Functions
  openSnackbar: PropTypes.func.isRequired
};

export default Router;

import React from "react";

const utils = {};

utils.breakLines = text => {
  return (
    text &&
    text.split("\n").map((line, i) => (
      <span key={i}>
        {line}
        <br />
      </span>
    ))
  );
};

export default utils;

import React, { Component } from "react";

import PropTypes from "prop-types";

import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import Toolbar from "@material-ui/core/Toolbar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import MailIcon from '@material-ui/icons/Mail';
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import pan from "../../assets/pan.svg";

import UserAvatar from "../UserAvatar";

class Bar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: {
        anchorEl: null
      }
    };
  }

  openMenu = event => {
    const anchorEl = event.currentTarget;

    this.setState({
      menu: {
        anchorEl
      }
    });
  };

  closeMenu = () => {
    this.setState({
      menu: {
        anchorEl: null
      }
    });
  };

  render() {
    // Properties
    const { performingAction, user, userData } = this.props;
    if (user) {
      delete user.phoneNumber;
      user.panelinha = true; //replace back - remove
    }

    // Events
    const {
      onAboutClick,
      onSettingsClick,
      onSignOutClick,
      onManageQuestionsClick,
      onManageUsersClick,
      onHomeClick,
      onCommentsClick,
      onNotificationsClick
    } = this.props;

    const { menu } = this.state;

    // const menuItems = [
    //   {
    //     name: "Sobre",
    //     onClick: onAboutClick
    //   },
    //   {
    //     name: "Configurações",
    //     onClick: onSettingsClick
    //   },
    //   {
    //     name: "Sair do app",
    //     divide: true,
    //     onClick: onSignOutClick
    //   }
    // ];

    const menuItems = [
      {
        name: "Início",
        onClick: onHomeClick
      }
    ];

    if (user && user.panelinha) {
      menuItems.push({
        name: "Meu perfil",
        onClick: onSettingsClick
      });
    }

    if (user && user.admin) {
      menuItems.push({
        name: "Gerenciar questões",
        onClick: onManageQuestionsClick
      });
      menuItems.push({
        name: "Gerenciar usuários",
        onClick: onManageUsersClick
      });
    }

    menuItems.push({
      name: "Sobre",
      divide: true,
      onClick: onAboutClick
    });

    menuItems.push({
      name: "Sair",
      divide: true,
      onClick: onSignOutClick
    });

    const style = {
      CFS: { backgroundColor: "darkblue" },
      CHO: { backgroundColor: "firebrick" },
      EAP: { backgroundColor: "goldenrod" }
    };

    let currentStyle = {
      color: "white",
      fontWeight: "bold",
      fontSize: 12,
      marginRight: 20
    };

    if ((userData && userData.clan) || (user && user.clan)) {
      currentStyle = { ...currentStyle, ...style[userData.clan] };
    }
    const day = (new Date()).getDate();
    let text;
    if (day < 14) {
      text = `Inscrições encerram em ${14 - day} dias!`;
    } else if (day === 14) {
      text = `ÚLTIMO DIA para inscrição!`;
    }

    const panelinhaLiberada = false;
    return (
      <>
        {user && (
          <AppBar color="primary" position="static">
            <Toolbar>
              <>
                <Box display="flex" flexGrow={1}>
                  <Avatar style={currentStyle}>{userData.clan}</Avatar>
                  <Typography color="inherit" variant="h6">
                    Olá {userData.username || userData.email}
                  </Typography>
                </Box>

                <Button color="white" onClick={onNotificationsClick}>
                  <Badge badgeContent={this.props.notificationsCount} color="secondary">
                    <MailIcon />
                  </Badge>
                </Button>

                <Button color="secondary" onClick={onCommentsClick}>
                  Questões comentadas
                </Button>

                <IconButton
                  color="inherit"
                  disabled={performingAction}
                  onClick={this.openMenu}
                >
                  <UserAvatar user={Object.assign(user, userData)} />
                </IconButton>

                <Menu
                  anchorEl={menu.anchorEl}
                  open={Boolean(menu.anchorEl)}
                  onClose={this.closeMenu}
                >
                  {menuItems.map((menuItem, index) => {
                    if (
                      menuItem.hasOwnProperty("condition") &&
                      !menuItem.condition
                    ) {
                      return null;
                    }

                    if (menuItem.divide) {
                      return (
                        <span key={index}>
                          <Divider />

                          <MenuItem
                            onClick={() => {
                              this.closeMenu();

                              menuItem.onClick();
                            }}
                          >
                            {menuItem.name}
                          </MenuItem>
                        </span>
                      );
                    }

                    return (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          this.closeMenu();

                          menuItem.onClick();
                        }}
                      >
                        {menuItem.name}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </>
            </Toolbar>
          </AppBar>
        )}
        {user && !user.panelinha && panelinhaLiberada && (
          <AppBar style={{backgroundColor: 'yellow', color: '#333333', cursor: 'pointer'}} position="static" onClick={
            () => {
              // window.open('https://panelinha2022.nery.net.br')
            }
            }>
            <Toolbar>
                <Box display="flex" flexGrow={1}>
                  <img src={pan} alt="Panelinha" />
                  <Typography color="inherit" align="center" variant="h6" style={{textAlign: 'center', marginLeft: 40}}>
                    PANELINHA 2022 - {text}
                  </Typography>
                </Box>
                </Toolbar>
                </AppBar>
        )}
      </>
    );
  }
}

Bar.defaultProps = {
  performingAction: false
};

Bar.propTypes = {
  // Properties
  performingAction: PropTypes.bool.isRequired,
  user: PropTypes.object,
  userData: PropTypes.object,

  // Events
  onAboutClick: PropTypes.func.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  onSignOutClick: PropTypes.func.isRequired,
  onManageQuestionsClick: PropTypes.func.isRequired,
  onManageUsersClick: PropTypes.func.isRequired,
  onHomeClick: PropTypes.func.isRequired,
  onCommentsClick: PropTypes.func.isRequired
};

export default Bar;

import React, { Component } from "react";

import PropTypes from "prop-types";

import { auth } from "../../firebase";

import { withStyles } from "@material-ui/core/styles";

import authentication from "../../services/authentication";

import Menu from "../Menu";

import Login from "../Login";

import StartupDialog from "../StartupDialog";
import ClanDialog from "../ClanDialog";

import { orange } from "@material-ui/core/colors";
const styles = theme => ({
  root: {
    flexGrow: 1,
    paddingTop: 20
  },
  menu: {
    marginTop: 150
  },
  cardAnswer: {
    width: 400,
    maxWidth: 500,
    backgroundColor: "#FAFAFA",
    color: "#333333",
    borderRadius: 20
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  button: {
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: orange[500],
    "&:hover": {
      backgroundColor: orange[700]
    }
  },
  media: {
    height: 300,
    width: 300,
    margin: "20px auto"
  }
});

const initialState = {
  mode: "",
  hits: 0,
  misses: 0,
  questions: [],
  loading: true,
  backdropOpen: false,
  exit: false,
  currentQuestion: {},
  answerImgType: "",
  answerTitle: "",
  answerText: "",
  stats: [],
  trainingTag: "",
  simuladoTag: "",
  currentVideo: {},
  dialogTermsOpen: true,
  dialogClanOpen: true
};

class HomeContent extends Component {
  constructor(props) {
    super(props);
    const { user, panelinha } = props;
    const acceptTerms = user && user.acceptTerms;
    const clan = user && user.clan;
/* replace back
    this.state = {
      ...initialState,
      dialogTermsOpen: panelinha && !acceptTerms,
      dialogClanOpen: panelinha && (!clan || clan.trim() === "")
    };
*/
    this.state = {
      ...initialState,
      dialogTermsOpen: !acceptTerms,
      dialogClanOpen: (!clan || clan.trim() === "")
    };
  };

  handleClickOpen = () => {
    this.setState({ dialogTermsOpen: true });
  };

  handleCloseTerms = () => {
    this.setState({ dialogTermsOpen: false });
  };

  handleCloseClan = () => {
    this.setState({ dialogClanOpen: false });
  };

  signInWithEmailLink = () => {
    const { user } = this.props;

    if (user) {
      return;
    }

    const emailLink = window.location.href;

    if (!emailLink) {
      return;
    }

    if (auth.isSignInWithEmailLink(emailLink)) {
      let emailAddress = localStorage.getItem("emailAddress");

      if (!emailAddress) {
        this.props.history.push("/");

        return;
      }

      authentication
        .signInWithEmailLink(emailAddress, emailLink)
        .then(value => {
          const user = value.user;
          const displayName = user.displayName;
          const emailAddress = user.email;

          this.props.openSnackbar(`Logado como ${displayName || emailAddress}`);
        })
        .catch(reason => {
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            case "auth/expired-action-code":
            case "auth/invalid-email":
            case "auth/user-disabled":
              this.props.openSnackbar(message);
              break;

            default:
              this.props.openSnackbar(message);
              return;
          }
        })
        .finally(() => {
          this.props.history.push("/");
        });
    }
  };

  render() {
    const { user, panelinha, classes, amplitude, setNotificationsCount } = this.props;
    // if (
    //   user &&
    //   !user.authorized &&
    //   Math.floor(
    //     Math.abs(
    //       (new Date().valueOf() -
    //         new Date(user.metadata.creationTime).valueOf()) /
    //         1000 /
    //         60 /
    //         60
    //     )
    //   ) > 24
    // ) {
    //   return (
    //     <EmptyState
    //       title="Usuário não autorizado!"
    //       description="Sistema exclusivo para alunos do Prof. Rubens Nery. Solicite acesso através de contato@nery.net.br"
    //       icon={<BlockSharpIcon />}
    //     />
    //   );
    // }

    if (user) {
      return (
        <>
          {/* <Button variant="outlined" color="secondary" onClick={this.handleClickOpen}>
            Open full-screen dialog
          </Button> */}
          <ClanDialog
            open={this.state.dialogClanOpen}
            handleClose={this.handleCloseClan}
            analytics={amplitude}
            user={user}
          />
          <StartupDialog
            open={this.state.dialogTermsOpen}
            handleClose={this.handleCloseTerms}
            analytics={amplitude}
            user={user}
          />
          <Menu
            classes={classes}
            changeMode={this.props.changeMode}
            mode={this.props.mode}
            user={user}
            panelinha={panelinha}
            amplitude={amplitude}
          />
        </>
      );
    }

    return (
      <Login
        onSignUpClick={this.props.onSignUpClick}
        dialogProps={this.props.dialogProps}
        openSnackbar={this.props.openSnackbar}
      />
    );
  }

  componentDidMount() {
    this.props.setNotificationsCount();
    this.signInWithEmailLink();
  }
}

HomeContent.propTypes = {
  user: PropTypes.object,
  changeMode: PropTypes.func
};

export default withStyles(styles)(HomeContent);

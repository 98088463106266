/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Container } from "@material-ui/core";

import httpClient from "../../services/httpClient";

import Question from "../Question/dark";
import QuestionAnswer from "../QuestionAnswer";

import ArcadeIcon from "../../assets/icon-arcade.svg";
import TrainingIcon from "../../assets/icon-treinamento.svg";
import Header from "../Header/Header";
import QuestionCounter from "../QuestionCounter";
import { useLocation, useParams } from "react-router-dom";

import { green, red, blue, orange } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: 20
  },
  menu: {
    marginTop: 150
  },
  cardAnswer: {
    width: 400,
    maxWidth: 500,
    backgroundColor: "#FAFAFA",
    color: "#333333",
    borderRadius: 20
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  button: {
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: orange[500],
    "&:hover": {
      backgroundColor: orange[700]
    }
  },
  media: {
    height: 300,
    width: 300,
    margin: "20px auto"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  scorePositive: {
    color: theme.palette.getContrastText(green[100]),
    backgroundColor: green[100],
    "&:hover": {
      backgroundColor: green[300]
    }
  },
  scoreNegative: {
    color: theme.palette.getContrastText(red[100]),
    backgroundColor: red[100],
    "&:hover": {
      backgroundColor: red[300]
    }
  },
  scoreNeutral: {
    color: theme.palette.getContrastText(blue[100]),
    backgroundColor: blue[100],
    "&:hover": {
      backgroundColor: blue[300]
    }
  }
}));

const parseMode = location => {
  const regex = new RegExp("/(.*?)/");
  const mode = location.pathname.match(regex)[1];
  return mode === "trainingMultiple" ? "training" : mode;
};

const QuestionPanel = props => {
  const classes = useStyles();
  const history = useHistory();
  const mode = parseMode(useLocation());
  const { tag, tags, questionId } = useParams();
  const { user, changeMode } = props;
  const [isLoading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [backdropState, setBackdropState] = useState(false);
  const [backdropProps, setBackdropProps] = useState({});
  const [score, setScore] = useState({ hits: 0, misses: 0 });
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [randomNum, setRandomNum] = useState(1);

  useEffect(() => {
    if (questions.length) {
      const currentQuestion = questions[0];
      currentQuestion.picked = true;
      currentQuestion.mode = mode;
      currentQuestion.start = new Date();
      setCurrentQuestion(currentQuestion);
      setLoading(false);
    }
  }, [questions]);

  const getNextQuestion = () => {
    const availableQuestions = questions.filter(q => !q.picked);
    if (availableQuestions && availableQuestions.length) {
      const currentQuestion = availableQuestions[0];
      currentQuestion.picked = true;
      currentQuestion.mode = mode;
      currentQuestion.start = new Date();
      setCurrentQuestion(currentQuestion);
    } else {
      fetchQuestions();
    }
  };

  const fetchQuestions = async () => {
    setLoading(true);
    const { data } = await httpClient.getQuestions(
      mode,
      tag || tags,
      questionId,
      user.uid
    );
    const questions = Array.isArray(data) ? data : data.questions;
    setTotalQuestions(data.count);
    setQuestions(questions);
    setLoading(false);
    // getNextQuestion();
  };

  useEffect(() => {
    if (user && user.uid && !questions.length) {
      fetchQuestions();

      [
        "img/error1.gif",
        "img/error2.gif",
        "img/error3.gif",
        "img/error4.gif",
        "img/error5.gif",
        "img/error6.gif",
        "img/success1.gif",
        "img/success2.gif",
        "img/success3.gif",
        "img/success4.gif",
        "img/success5.gif",
        "img/success6.gif"
      ].forEach(img => (new Image().src = img));
    }
  }, []);

  const updateScore = imgType => {
    if (imgType === "error") {
      setScore({ ...score, misses: score.misses + 1 });
    } else {
      setScore({ ...score, hits: score.hits + 1 });
    }
  };

  const updateElapsedTime = () => {
    const newCurrentQuestion = { ...currentQuestion };
    newCurrentQuestion.end = new Date();
    newCurrentQuestion.elapsedTime = Math.floor(
      Math.abs(
        (newCurrentQuestion.start.getTime() -
          newCurrentQuestion.end.getTime()) /
          1000
      )
    );
    setCurrentQuestion(newCurrentQuestion);
  };

  const updateStats = imgType => {
    updateScore(imgType);
    updateElapsedTime();
  };

  const handleBackdrop = props => {
    setRandomNum(parseInt(Math.floor(Math.random() * 6) + 1));
    setBackdropState(!backdropState);

    if (props.imgType) {
      updateStats(props.imgType);

      setBackdropProps({
        answerImgType: props.imgType,
        answerText: props.text,
        answerTitle: props.title
      });
    }
  };

  const handleClose = (prop, imgType) => {
    if (prop === "next" && mode !== "training") {
      setBackdropState(false);
      getNextQuestion();
    } else if (prop === "next") {
      updateStats(imgType);
      getNextQuestion();
    } else {
      setBackdropState(false);
      history.push({
        pathname: "/"
      });
      changeMode("");
    }
  };

  return (
    <>
      <QuestionAnswer
        imgType={backdropProps.answerImgType}
        title={backdropProps.answerTitle}
        text={backdropProps.answerText}
        randomNum={randomNum}
        classes={classes}
        open={backdropState}
        handleClose={handleClose}
        mode={mode}
        correta={currentQuestion.correta}
        question={currentQuestion}
        user={user}
      />
      <Container xs={12} md={6} lg={3}>
        {!questionId && (
          <>
            <Header
              icon={
                mode === "arcade"
                  ? ArcadeIcon
                  : mode === "training"
                  ? TrainingIcon
                  : TrainingIcon
              }
              type="results"
              results={{ correct: score.hits, wrong: score.misses }}
            />
            <QuestionCounter
              tag={tag}
              count={parseInt(score.hits) + parseInt(score.misses)}
              totalQuestions={totalQuestions}
              percent={currentQuestion.percent}
            />
          </>
        )}
        <Question
          mode={mode}
          user={props.user}
          loading={isLoading}
          classes={classes}
          showResult={handleBackdrop}
          value={currentQuestion}
          handleClose={handleClose}
          panelinha={props.panelinha}
          questionId={questionId}
        />
      </Container>
    </>
  );
};

export default QuestionPanel;

import React from "react";
import { useHistory } from "react-router";

import { Grid, Typography } from "@material-ui/core";
import ModeChoiceButton from "../ModeChoiceButton";

const MenuArcade = props => {
  const history = useHistory();
  return (
    <div style={{ flexGrow: 1, maxWidth: 470, margin: "15px auto" }}>
      <Typography
        style={{ margin: "20px 15px", fontSize: 14, fontWeight: "bold" }}
      >
        Escolha sua trilha:
      </Typography>
      <Grid container direction="column" spacing={0}>
        <Grid item xs={12}>
          <ModeChoiceButton
            title="CFS"
            onClick={() => {
              props.changeArcadeTag("CFS");
              history.push({
                pathname: "/arcade/CFS"
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ModeChoiceButton
            title="CHO"
            onClick={() => {
              props.changeArcadeTag("CHO");
              history.push({
                pathname: "/arcade/CHO"
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ModeChoiceButton
            title="EAP"
            onClick={() => {
              props.changeArcadeTag("EAP");
              history.push({
                pathname: "/arcade/EAP"
              });
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default MenuArcade;

import React, { useEffect, useState } from 'react';
import httpClient from '../../services/httpClient';

import SimuladoList from '../../components/SimuladoList';
import SimuladoInfo from '../../components/SimuladoInfo';
import SimuladoQuestionPanel from '../../components/SimuladoQuestionPanel';
import SimuladoQuestionReviewPanel from '../../components/SimuladoQuestionReviewPanel';
import SimuladoResult from '../../components/SimuladoResult';

const Simulado = props => {
  const { user } = props;
  const [mocks, setMocks] = useState([]);
  const [mockTest, setMockTest] = useState({});
  const [review, setReview] = useState(false);

  useEffect(() => {
    httpClient.getMockTests(user.uid).then(response => {
      setMocks(response.data);
    });
  }, [user.email]);

  const showMockInfo = mock => {
    httpClient.getQuestions('mockTests', mock.tag).then(response => {
      //setMockTest({ ...mock, questions: response.data.slice(0, 10) });
      setMockTest({ ...mock, questions: response.data });
    });
  };

  const start = async () => {
    httpClient
      .postMockTestStarted(
        mockTest._id,
        mockTest.title,
        mockTest.startTime,
        user.uid
      )
      .then(response => {
        setMockTest({
          ...mockTest,
          started: true,
          startTime: new Date(),
          progressId: response.data.id
        });
      });
  };

  if (!mockTest.questions || !mockTest.questions.length) {
    return <SimuladoList mocks={mocks} setMock={showMockInfo} />;
  }

  if (!mockTest.started) {
    return <SimuladoInfo mock={mockTest} onClick={start} />;
  }

  if (mockTest.started && mockTest.questions.length && !mockTest.finished) {
    return (
      <SimuladoQuestionPanel
        mock={mockTest}
        setMockTest={setMockTest}
        user={user}
      />
    );
  }
  if (mockTest.finished && review) {
    return (
      <SimuladoQuestionReviewPanel mock={mockTest} setReview={setReview} />
    );
  }

  if (mockTest.finished) {
    return <SimuladoResult mock={mockTest} setReview={setReview} />;
  }
};

export default Simulado;

import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Box,
  Grid,
  List,
  Tabs,
  Tab,
  Typography
} from '@material-ui/core';

import httpClient from '../../services/httpClient';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 660,
    margin: '0 auto',
    marginTop: 30
  },
  list: {
    backgroundColor: '#ffffff',
    color: '#333333',
    borderRadius: 15,
    marginLeft: 8,
    marginRight: 8,
    minHeight: 80,
    padding: 10,
    marginTop: 15,
    cursor: 'pointer'
  },
  newComment: {
    backgroundColor: '#fffc9e',
    border: '2px solid #eb5757'
  },
  redBar: {
    backgroundColor: '#EB5757',
    width: 48,
    height: 8,
    marginBottom: 15
  }
}));

const Comments = props => {
  const { user } = props;
  user.panelinha = true; // replace back - remove
  const classes = useStyles();
  const history = useHistory();
  const [questions, setQuestions] = useState([]);
  const [userQuestions, setUserQuestions] = useState([]);

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  };

  useEffect(() => {
    httpClient.getCommentsFromUser(user.email, true).then(result => {
      setQuestions(result);
    });

    httpClient
      .getCommentsFromUser(user.email, false)
      .then(result => setUserQuestions(result));
  }, [user]);

  const markAsRead = notificationId => {
    httpClient.patchNotificationRead(notificationId);
  };

  const Header = () => (
    <Grid item xs={12}>
      <Typography variant="h5">Questões comentadas</Typography>
      <div className={classes.redBar}>&nbsp;</div>
    </Grid>
  );

  const Questions = ({ list }) => {
    if (list.length) {
      return list.map(question => {
        if (
          question &&
          question.question[0] &&
          question.question[0].enunciado
        ) {
          return (
            <Grid
              item
              xs={10}
              className={`${classes.list} ${
                question.notification && question.notification.length
                  ? classes.newComment
                  : ''
              }`}
              onClick={() => {
                if (question.questionId) {
                  if (question.notification && question.notification.length) {
                    markAsRead(question.notification[0]);
                  }
                  history.push({
                    pathname: `/question/${question.questionId}`
                  });
                }
              }}
            >
              {`${question.question[0].enunciado.slice(0, 200)} ${
                question.question[0].enunciado.length > 200 ? '...' : ''
              }`}
            </Grid>
          );
        } else {
          console.log('Comment broken ', question._id);
          return '';
        }
      });
    }
    if (user && user.panelinha) {
      return (
        <Grid item xs={10} className={classes.list}>
          Nenhuma questão comentada por enquanto!
          <br />
          Utilize a caixa de comentários disponível no Modo Treinamento para
          interagir.
          <br />
          <br />
          As questões que você comentar aparecerão aqui para acompanhar a
          resposta do Prof. Nery.
        </Grid>
      );
    }
    window.location.href = 'https://caixapreta.nery.net.br'; // 'https://panelinha2022.nery.net.br';
    return '';
  };

  const QuestionTabs = () => {
    return (
      <>
        <AppBar position="static">
          <Tabs value={value} variant="fullWidth" onChange={handleChange}>
            <Tab label="MINHAS QUESTÕES" />
            <Tab label="TODAS" />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <List
            className={classes.list}
            component="nav"
            key="rankingListComponent"
          >
            <Grid container className={classes.root}>
              <Questions key="userList" list={userQuestions} />
            </Grid>
          </List>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <List
            className={classes.list}
            component="nav"
            key="rankingListComponent"
          >
            <Grid container className={classes.root}>
              <Questions key="userList" list={questions} />
            </Grid>
          </List>
        </TabPanel>
      </>
    );
  };

  return (
    <Grid container className={classes.root}>
      <Header />
      <QuestionTabs />
    </Grid>
  );
};

export default Comments;

import { get, post, patch } from 'axios';

let baseUrl = 'https://caixa-preta-api.herokuapp.com';

if (process.env.NODE_ENV === 'development') {
  baseUrl = 'http://localhost:3500';
}
const httpClient = {};

const getRanking = async (currentUser, tag) => {
  let url = `${baseUrl}/users/stats?u=${currentUser}`;
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  };

  return get(url, config);
};
httpClient.postComment = async (user, questionId, text) => {
  const url = `${baseUrl}/questions/comment`;
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  };
  const { email, admin, alias } = user;

  const data = {
    user: { email, admin, alias },
    questionId,
    text
  };
  return post(url, data, config);
};
httpClient.patchNotificationRead = async notificationId => {
  const url = `${baseUrl}/notifications/${notificationId}/read`;
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  };

  return patch(url, {}, config);
};

httpClient.sendEmail = async user => {
  const url = `${baseUrl}/subscription`;
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  };
  const { email, uid } = user;

  const data = {
    email,
    uid
  };

  return post(url, data, config);
};
httpClient.getComments = async questionId => {
  let url = `${baseUrl}/questions/${questionId}/comments`;
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  };

  const response = await get(url, config);

  return response.data;
};
httpClient.getCommentsFromUser = async (email, admin) => {
  let url = `${baseUrl}/comments/${email}?a=${admin}`;
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  };

  const response = await get(url, config);
  return response.data;
};

httpClient.getNotificationsFromUser = async (email, admin) => {
  let url = `${baseUrl}/notifications/${email}?a=${admin}`;
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  };

  const response = await get(url, config);
  return response.data;
};


httpClient.getNotificationsCount = async (email) => {
  let url = `${baseUrl}/notifications/${email}/count`;
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  };

  const response = await get(url, config);
  return response.data;
};

httpClient.getRanking = getRanking;
httpClient.getQuestions = (mode, tag, questionId, uid) => {
  let url;
  if (questionId) {
    url = `${baseUrl}/question/${questionId}`;
  } else {
    url = `${baseUrl}/questions/${mode}/${encodeURIComponent(tag)}?uid=${uid}`;
  }
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  };
  return get(url, config);
};

httpClient.getTags = uid => {
  let url = `${baseUrl}/tags/${uid}`;
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  };
  return get(url, config);
};

httpClient.getAttachments = questionId => {
  let url = `${baseUrl}/tags/attachments?questionId=${questionId}`;
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  };
  return get(url, config);
};

httpClient.getMockTests = props => {
  const url = `${baseUrl}/mockTests?u=${props}`;
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  };
  return get(url, config);
};

httpClient.postMockTestStarted = (id, title, startTime, user) => {
  const url = `${baseUrl}/mockTestProgress`;
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  };
  return post(url, { id, title, startTime, user }, config);
};

httpClient.patchMockTestProgress = (id, props) => {
  const url = `${baseUrl}/mockTestProgress/${id}`;
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  };
  return patch(url, props, config);
};

httpClient.getUserStats = props => {
  const url = `${baseUrl}/user/${props}/stats`;
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  };
  return get(url, config);
};

httpClient.getAllUsersInfo = props => {
  const url = `${baseUrl}/users`;
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  };
  return get(url, config);
};

httpClient.getVideos = uid => {
  const url = `${baseUrl}/questions/liveVideos?uid=${uid}`;
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  };
  return get(url, config);
};

httpClient.changeUserPermissions = props => {
  const url = `${baseUrl}/users`;
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  };
  return patch(url, props, config);
};

httpClient.patchUser = (uid, data) => {
  const url = `${baseUrl}/users/${uid}`;
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  };
  return patch(url, data, config);
};

httpClient.postAnswer = props => {
  const url = `${baseUrl}/questions/answer`;
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  };
  return post(url, props, config);
};

httpClient.registerWatch = props => {
  const url = `${baseUrl}/questions/liveVideos/watch`;
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  };
  return post(url, props, config);
};

httpClient.reportError = data => {
  const url = `${baseUrl}/questions/report`;
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  };
  return post(url, data, config);
};

httpClient.getBizus = userId => {
  let url = `${baseUrl}/bizus?userId=${userId}`;
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  };
  return get(url, config);
};

httpClient.getBizuFile = (id, userId) => {
  let url = `${baseUrl}/bizus/${id}?userId=${userId}`;
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  };
  return get(url, config);
};

httpClient.getTotalQuestions = () => {
  let url = `${baseUrl}/questions/total`;
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  };
  return get(url, config);
};

httpClient.postNotification = (uid, audience, text, notificationUrl, showAlert = false) => {
  const url = `${baseUrl}/notifications`;
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  };
  const data = {
    uid, audience, text, url: notificationUrl, showAlert
  }
  return post(url, data, config);
};

export default httpClient;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 660,
    margin: "0 auto",
    backgroundColor: "#ffffff",
    color: "#333333",
    borderRadius: 20,
    marginTop: 40,
    padding: 20
  },
  title: {
    margin: "20px 15px",
    fontSize: 22,
    fontWeight: "bold"
  },
  info: {
    fontSize: 16,
    lineHeight: 3
  },
  button: {
    borderRadius: 20,
    margin: 20,
    fontSize: 18
  },
  center: {
    textAlign: "center"
  }
}));

const SimuladoInfo = props => {
  const { mock } = props;
  const classes = useStyles();

  const Info = () => (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <p className={classes.title}>{mock.title}</p>
        </Grid>
        <Grid item xs={12} className={classes.info}>
          <ol>
            <li>Este simulado contém {mock.questions.length} questões.</li>
            <li>
              Responda as questões e marque a alternativa desejada, o gabarito
              ficará disponível ao final.
            </li>
            <li>
              Ao clicar em INICIAR o seu simulado terá início. Caso você não
              seja concluído você poderá repeti-lo em 10 dias.
            </li>
            <li>
              Somente será possível assinalar uma única alternativa, no dia da
              sua prova tome cuidado para não marcar mais de uma.
            </li>
            <li>
              Esta prova tem duração máxima de {mock.timeToComplete} minutos,
              incluindo o preenchimento da folha de respostas. Após esse prazo o
              simulado será encerrado.
            </li>
            <li>Prova {mock.type} consulta.</li>
            <li>A interpretação faz parte da prova.</li>
            <li>
              Você estará mais preparado ao fazer esse simulado, mas não
              necessariamente as questões dele cairão na prova.
            </li>
            <li>
              Se você leu até aqui provavelmente terá chances de ir bem na
              prova.
            </li>
            <li>Boa prova!</li>
          </ol>
        </Grid>
        <Grid item xs={12} className={classes.center}>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={props.onClick}
          >
            Iniciar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  return <Info />;
};

export default SimuladoInfo;

import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { Button, Grid } from "@material-ui/core";

const useStyles = makeStyles({
  group: {
    width: "100%"
  },
  item: {
    background: "white",
    textAlign: "left",
    justifyContent: "flex-start",
    color: "#000000",
    fontWeight: 400,
    margin: 10,
    border: "1px solid #ccc",
    borderRadius: 20,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#CCC"
    },
    "&:not(:first-child)": {
      borderRadius: 20
    },
    "&:first-child": {
      borderRadius: 20
    }
  },
  confirmButton: {
    borderRadius: 20
  },
  center: {
    textAlign: "center"
  },
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  wrong: {
    background: "#ff8a8a !important",
    "&:hover": {
      backgroundColor: "#ff8a8a !important"
    }
  },
  rightQuestion: {
    background: "#aef5c8",
    "&:hover": {
      backgroundColor: "#aef5c8"
    }
  }
});

const ButtonPanel = props => {
  const { question } = props;
  const classes = useStyles();

  const goBack = () => {
    props.setNextQuestion(-1);
  };

  const confirmSelection = (
    { question, user, setNextQuestion },
    nextIndex,
    finished
  ) => setNextQuestion(nextIndex);

  const getClassName = option => {
    if (option && question.selected) {
      return `${classes.item} ${
        question.correta.toLowerCase() === option.toLowerCase()
          ? classes.rightQuestion
          : question.selected.toLowerCase() === option.toLowerCase() &&
            question.correta.toLowerCase() !== question.selected.toLowerCase()
          ? classes.wrong
          : ""
      }`;
    }
    return classes.item;
  };

  return (
    <Grid container alignContent="center" alignItems="center">
      <Grid item xs={12}>
        <ToggleButtonGroup
          className={classes.group}
          exclusive
          orientation="vertical"
          aria-label="Alternativas"
        >
          <ToggleButton
            value="a"
            aria-label="Alternativa A"
            className={getClassName("a")}
          >
            {props.question.altA}
          </ToggleButton>
          <ToggleButton
            value="b"
            aria-label="Alternativa B"
            className={getClassName("b")}
          >
            {props.question.altB}
          </ToggleButton>
          <ToggleButton
            value="c"
            aria-label="Alternativa C"
            className={getClassName("c")}
          >
            {props.question.altC}
          </ToggleButton>
          <ToggleButton
            value="d"
            aria-label="Alternativa D"
            className={getClassName("d")}
          >
            {props.question.altD}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={3} className={classes.left}>
        {props.index > 0 && (
          <Button
            className={classes.confirmButton}
            variant="contained"
            color="secondary"
            onClick={() => goBack()}
          >
            Anterior
          </Button>
        )}
      </Grid>
      <Grid item xs={6}>
        &nbsp;
      </Grid>
      <Grid item xs={3} className={classes.right}>
        {props.index < props.total - 1 && (
          <Button
            className={classes.confirmButton}
            variant="contained"
            color="secondary"
            onClick={() => confirmSelection(props, 1)}
          >
            Próxima
          </Button>
        )}
      </Grid>
      <Grid item xs={12} className={classes.center}>
        <Button
          className={classes.confirmButton}
          variant="contained"
          color="secondary"
          onClick={() => props.setReview(false)}
        >
          Voltar para o resumo
        </Button>
      </Grid>
    </Grid>
  );
};

export default ButtonPanel;

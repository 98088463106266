/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";

import Question from "../SimuladoQuestionReview";

const useStyles = makeStyles(theme => ({
  header: {
    marginTop: 20,
    marginBottom: 50
  },
  redBox: {
    backgroundColor: "#EB5757",
    width: "50%",
    marginBottom: 50,
    borderRadius: 20,
    padding: 10
  },
  title: {
    fontSize: 24
  },
  rightBorder: {
    borderRight: "2px solid #333333"
  },
  realtimeData: {
    textAlign: "center",
    color: "#FAFAFA",
    heigth: "100%"
  },
  number: {
    fontWeight: "bold",
    fontSize: "42px",
    margin: 0,
    padding: 0
  }
}));
const SimuladoQuestionReviewPanel = props => {
  const classes = useStyles();
  const { mock } = props;
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(
    mock.questions[currentQuestionIndex]
  );

  useEffect(() => {
    setCurrentQuestion(mock.questions[currentQuestionIndex]);
  }, [currentQuestionIndex]);

  const setNextQuestion = positionToAdd => {
    const newIndex = currentQuestionIndex + positionToAdd;
    if (newIndex < mock.questions.length) {
      setCurrentQuestionIndex(newIndex);
    }
  };

  return (
    <>
      <Container xs={12} md={6} lg={3}>
        <Grid container className={classes.header}>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.title}>
              {mock.title}
            </Typography>
          </Grid>
        </Grid>
        <Question
          user={props.user}
          index={currentQuestionIndex}
          total={mock.questions.length}
          classes={props.classes}
          value={currentQuestion}
          setNextQuestion={setNextQuestion}
          setReview={props.setReview}
        />
      </Container>
    </>
  );
};

export default SimuladoQuestionReviewPanel;

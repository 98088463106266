import React from "react";
import { Grid, InputBase, IconButton, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CheckIcon from "@material-ui/icons/Check";
import WrongIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
  root: {
    height: 120,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    backgroundSize: "144px 144px",
    paddingTop: 32,
    paddingLeft: 12
  },
  inputContainer: {
    background: "white",
    color: "#999999",
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    height: 48
  },
  input: {
    background: "white",
    color: "#999999",
    borderRadius: 20,
    fontSize: 12,
    paddingLeft: 12,
    paddingRight: 12,
    width: "90%"
  },
  iconButton: {
    color: "#333333"
  },
  resultContainer: {
    padding: 8
  },
  correct: {
    color: "#27AE60",
    display: "flex"
  },
  wrong: {
    color: "#E00803",
    display: "flex"
  },
  neutral: {
    color: "#333333",
    display: "flex"
  },
  resultText: {
    fontSize: 16
  }
}));

const Header = props => {
  const classes = useStyles();

  const InputHeader = props => (
    <>
      <InputBase
        className={classes.input}
        id="filterText"
        label={props.placeholder}
        placeholder={props.placeholder}
        key="filterTextField"
        onChange={props.onChange}
        value={props.text}
        autoFocus
      />
      <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>
    </>
  );

  const ResultHeader = props => (
    <Grid container className={classes.resultContainer}>
      <Grid item xs={6} className={classes.wrong} alignItems="flex-start">
        <WrongIcon className={classes.icons} />
        <Typography className={classes.resultText}>
          {props.results.wrong} erros
        </Typography>
      </Grid>
      <Grid item xs={6} className={classes.correct} alignItems="flex-start">
        <CheckIcon className={classes.icons} />
        <Typography>{props.results.correct} acertos</Typography>
      </Grid>
    </Grid>
  );

  const HeaderContent = props => {
    if (props.type === "results") {
      return <ResultHeader results={props.results} />;
    }
    return (
      <InputHeader
        key="filterInputHeader"
        onChange={props.onChange}
        text={props.text}
        placeholder={props.placeholder}
      />
    );
  };

  return (
    <Grid
      container
      className={classes.root}
      style={{ backgroundImage: `url(${props.icon})` }}
    >
      <Grid item xs={9} className={classes.inputContainer}>
        <HeaderContent
          key="filterHeaderContent"
          type={props.type}
          onChange={props.onChange}
          placeholder={props.placeholder}
          text={props.text}
          results={props.results}
        />
      </Grid>
    </Grid>
  );
};

export default Header;

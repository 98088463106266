import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";

import httpClient from "../../services/httpClient";

import TimerIcon from "@material-ui/icons/Timer";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const AdminContent = props => {
  const { user } = props;
  const [rows, setRows] = useState([]);

  const fetchData = () => {
    httpClient.getAllUsersInfo().then(users => {
      setRows(users.data);
    });
  };

  const changePermissions = async (
    uid,
    lockState,
    property = "authorized",
    email
  ) => {
    const result = await httpClient.changeUserPermissions({
      uid,
      lockState,
      property,
      email
    });

    if (result.status === 202) {
      const users = [...rows];
      const user = users.find(u => u.uid === uid);
      user[property] = !user[property];
      setRows(users);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const classes = useStyles();

  if (user && user.admin) {
    return (
      <>
        <Typography variant="h4">
          Usuários {rows.length > 0 && ` - ${rows.length} registros`}
        </Typography>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>Usuário</TableCell>
                <TableCell align="right">Criado em</TableCell>
                <TableCell align="right">Conectado em</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    <Avatar src={row.photoURL} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.email}
                  </TableCell>
                  <TableCell align="right">{row.creationTime}</TableCell>
                  <TableCell align="right">{row.lastSignInTime}</TableCell>
                  <TableCell align="right">
                    {!row.trial24Hours && !row.authorized && !row.admin && (
                      <Tooltip title="Usuário bloqueado">
                        <LockOutlinedIcon />
                      </Tooltip>
                    )}
                    {row.trial24Hours && (
                      <Tooltip title="Dentro do trial de 24 horas">
                        <TimerIcon textRendering="" />
                      </Tooltip>
                    )}
                    {row.authorized && !row.admin && (
                      <Tooltip title="Usuário autorizado!">
                        <CheckCircleOutlineIcon />
                      </Tooltip>
                    )}
                    {row.admin && (
                      <Tooltip title="BOSS!">
                        <AllInclusiveIcon />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {!row.panelinha && !row.admin && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          changePermissions(
                            row.uid,
                            true,
                            "panelinha",
                            row.email
                          )
                        }
                      >
                        Adicionar à panelinha
                      </Button>
                    )}
                    {row.authorized && !row.admin && row.panelinha && (
                      <>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() =>
                            changePermissions(row.uid, false, "panelinha")
                          }
                        >
                          Remover da panelinha
                        </Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
  return <p align="center">Acesso não autorizado</p>;
};

export default AdminContent;

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  Checkbox,
  Box,
  FormControlLabel,
  AppBar,
  Toolbar
} from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import { Grid } from '@material-ui/core';

import authentication from '../../services/authentication';

import caixaPretaLogo from '../../assets/lg-cp.png';
import BadgeBlue from '../../assets/badge-blue.png';
import BadgeRed from '../../assets/badge-red.png';
import BadgeYellow from '../../assets/badge-yellow2.png';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  logo: {
    height: 100
  },
  box: {
    background: 'rgba(48, 48, 48, .3)',
    padding: 20
  },
  buttonCFS: {
    height: '128px',
    marginBottom: 20,
    backgroundColor: 'darkblue',
    fontSize: 36,
    '&:active': {
      border: '5px solid white'
    },
    '&:hover': {
      border: '5px solid white',
      backgroundColor: 'darkblue'
    }
  },
  buttonCHO: {
    height: '128px',
    marginBottom: 20,
    backgroundColor: 'firebrick',
    fontSize: 36,
    '&:active': {
      border: '5px solid white'
    },
    '&:hover': {
      border: '5px solid white',
      backgroundColor: 'firebrick'
    }
  },
  buttonEAP: {
    height: '128px',
    marginBottom: 20,
    backgroundColor: 'goldenrod',
    fontSize: 36,
    '&:active': {
      border: '5px solid white'
    },
    '&:hover': {
      border: '5px solid white',
      backgroundColor: 'goldenrod'
    }
  },
  selectedButton: {
    border: '5px solid white'
  }
}));

const CLAN_OPTIONS = {
  EAP: 'EAP',
  CFS: 'CFS',
  CHO: 'CHO'
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ClanDialog = ({ analytics, open, handleClose }) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [clan, setClan] = useState('');

  const handleChange = event => {
    setChecked(event.target.checked);
  };

  const saveChangesAndClose = async event => {
    if (checked) {
      analytics.logEvent('clan_chosen', { clan });

      authentication.setClan(clan).then(() => {
        handleClose(event);
      });
    }
  };

  const TopBar = () => (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          Escolha seu Clã
        </Typography>
        <Button
          color="inherit"
          onClick={saveChangesAndClose}
          disabled={!checked}
        >
          Salvar
        </Button>
      </Toolbar>
    </AppBar>
  );

  const DialogContent = () => (
    <div
      style={{
        flexGrow: 1,
        maxWidth: 600,
        margin: '15px auto'
      }}
    >
      <Grid container className={classes.box}>
        <Grid item xs={2}>
          <img
            src={caixaPretaLogo}
            alt="Logo Caixa Preta"
            className={classes.logo}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={9}>
          <Typography variant="h4" align="center">
            Clã
          </Typography>
          <Typography variant="h6" align="center">
            Escolha o clã ao qual você pertence
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.box}>
        <Grid item xs={12}>
          <Button
            onClick={() => setClan('CFS')}
            defaultValue="CFS"
            variant="contained"
            className={`${classes.buttonCFS} ${
              clan === CLAN_OPTIONS.CFS ? classes.selectedButton : ''
            }`}
            color="secondary"
            size="large"
            fullWidth={true}
            startIcon={
              <img
                src={BadgeBlue}
                alt="Emblema azul"
                className={classes.logo}
              />
            }
          >
            CFS
          </Button>
          <Button
            onClick={() => setClan('CHO')}
            defaultValue="CHO"
            variant="contained"
            className={`${classes.buttonCHO} ${
              clan === CLAN_OPTIONS.CHO ? classes.selectedButton : ''
            }`}
            color="secondary"
            size="large"
            fullWidth={true}
            startIcon={
              <img
                src={BadgeRed}
                alt="Emblema vermelho"
                className={classes.logo}
              />
            }
          >
            CHO
          </Button>
          <Button
            onClick={() => setClan('EAP')}
            defaultValue="EAP"
            variant="contained"
            className={`${classes.buttonEAP} ${
              clan === CLAN_OPTIONS.EAP ? classes.selectedButton : ''
            }`}
            color="secondary"
            size="large"
            fullWidth={true}
            startIcon={
              <img
                src={BadgeYellow}
                alt="Emblema amarelo"
                className={classes.logo}
              />
            }
          >
            EAP
          </Button>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                name="checked"
              />
            }
            label={`Estou ciente que não poderei alterar meu clã durante o ano de ${new Date().getFullYear()}`}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              color="secondary"
              variant="outlined"
              onClick={saveChangesAndClose}
              disabled={!checked}
            >
              Salvar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={saveChangesAndClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        TransitionComponent={Transition}
      >
        <TopBar />
        <DialogContent />
      </Dialog>
    </div>
  );
};

export default ClanDialog;

import React from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import pan from '../../assets/pan.svg';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ffffff',
    color: '#999999',
    minHeight: 120,
    borderRadius: 20,
    boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.25)',
    MozBoxShadow: '0px 4px 5px 0px rgba(0,0,0,0.25)',
    WebkitBoxShadow: '0px 4px 5px 0px rgba(0,0,0,0.25)',
    margin: '24px 16px'
  },
  icons: {
    height: 104,
    width: 104,
    margin: '10px 10px 0 10px',
    opacity: 0.5
  },
  title: {
    fontWeight: 'bold',
    fontSize: 18,
    marginTop: 8,
    padding: '0 15px'
  },
  titleOnly: {
    fontWeight: 'bold',
    fontSize: 48,
    marginTop: 8,
    padding: '10px 125px'
  },
  description: {
    fontSize: 14,
    marginTop: 8,
    padding: '0 15px'
  },
  tarja: {
    backgroundColor: 'yellow',
    color: '#333333',
    padding: 5,
    textAlign: 'center',
    letterSpacing: 5
  },
  pan: {
    width: 28,
    height: 28
  }
}));

const ModeChoiceButtonPanelinha = props => {
  const classes = useStyles(props);

  const showModalPayment = () => {
    // window.open("https://panelinha2022.nery.net.br");
  };

  return (
    <Box className={classes.root} onClick={showModalPayment}>
      <div className={classes.tarja}>
        EXCLUSIVO PARA A PANELINHA{' '}
        <img className={classes.pan} src={pan} alt="Panelinha" />
      </div>
      <Grid container>
        {props.icon && (
          <>
            {' '}
            <Grid item xs={4}>
              <img
                className={classes.icons}
                src={props.icon}
                alt={props.title}
                title={props.title}
              />
            </Grid>
            <Grid item xs={8}>
              <Box>
                <Typography className={classes.title}>{props.title}</Typography>
                <Typography className={classes.description}>
                  {props.description}
                </Typography>
              </Box>
            </Grid>{' '}
          </>
        )}
        {!props.icon && (
          <Box>
            <Typography className={classes.titleOnly}>{props.title}</Typography>
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default ModeChoiceButtonPanelinha;

import React from "react";

import { Typography } from "@material-ui/core";
import utils from "../../services/utils";

const QuestionTitle = props => {
  return (
    <Typography
      align="justify"
      variant="subtitle2"
      color="textSecondary"
      gutterBottom
    >
      {utils.breakLines(props.text)}
    </Typography>
  );
};

export default QuestionTitle;

import React from "react";
import {
  Backdrop,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Snackbar
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import WrongIcon from "@material-ui/icons/Close";

const useStyles = makeStyles({
  icon: {
    fontSize: 32
  },
  correct: {
    color: "#27AE60",
    display: "flex",
    padding: 8
  },
  wrong: {
    color: "#E00803",
    display: "flex",
    padding: 8
  },
  image: {
    height: 300,
    width: 300,
    margin: "10px auto"
  },
  description: {
    color: "#333333"
  },
  btnNext: {
    borderRadius: 20,
    marginBottom: 20
  },
  btnExit: {
    borderRadius: 20,
    marginBottom: 30
  }
});

const ArcadeAnswer = props => {
  const classes = useStyles();

  const titleClass =
    props.props.imgType === "success" ? classes.correct : classes.wrong;

  const Icon = () =>
    props.props.imgType === "success" ? (
      <CheckIcon className={classes.icon} />
    ) : (
      <WrongIcon className={classes.icon} />
    );

  const Title = () => (
    <Grid container justify="center" alignItems="center">
      <Grid
        item
        xs={12}
        className={titleClass}
        alignContent="center"
        alignItems="flex-start"
        align="center"
        justify="center"
      >
        <Icon />
        <Typography gutterBottom variant="h5" component="h2">
          {props.props.title}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Title />
      <CardMedia
        className={classes.image}
        image={"/img/" + props.props.imgType + props.props.randomNum + ".gif"}
        title={props.props.title}
      />
      <CardContent>
        <Typography
          variant="body2"
          className={classes.description}
          align="center"
          alignContent="center"
          alignItems="center"
          component="p"
        >
          {props.props.text}
        </Typography>
      </CardContent>
      <CardActions>
        <Grid container justify="center" alignItems="center">
          <Grid
            item
            xs={12}
            alignContent="center"
            alignItems="flex-start"
            align="center"
            justify="center"
          >
            <Button
              color="primary"
              variant="contained"
              className={classes.btnNext}
              onClick={() => {
                props.props.handleClose("next");
              }}
            >
              Vambora!
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            alignContent="center"
            alignItems="flex-start"
            align="center"
            justify="center"
          >
            <Button
              size="small"
              color="primary"
              className={classes.btnExit}
              onClick={() => {
                props.props.handleClose("exit");
              }}
            >
              Sair
            </Button>
          </Grid>

          {/* <Grid item xs={12}>
            <ReportQuestion user={user} question={question} />
          </Grid> */}
        </Grid>
      </CardActions>
    </>
  );
};

const TrainingAnswer = props => {
  const severity = props.props.imgType;
  const message =
    severity === "error"
      ? `Resposta errada! A alternativa certa é: ${props.props.correta}`
      : "Parabéns! Acertou a resposta";

  return (
    <>
      <Snackbar open={props.props.open} onClose={props.nextAction}>
        <Alert onClose={props.nextAction} severity={severity}>
          {message}
          <Grid container>
            <Grid item xs={1}>
              <Button
                size="small"
                color="secondary"
                onClick={() => {
                  props.props.handleClose("exit");
                }}
              >
                Desistir
              </Button>
            </Grid>
            <Grid item xs={7} md={7} lg={7}>
              &nbsp;
            </Grid>
            <Grid item xs={1}>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => {
                  props.props.handleClose("next");
                }}
              >
                Vambora!
              </Button>
            </Grid>
          </Grid>
          {/* <Grid container>
            <Grid item xs={12}>
              <ReportQuestion user={user} question={question} />
            </Grid>
          </Grid> */}
        </Alert>
      </Snackbar>
    </>
  );
};

const RenderAnswer = props => {
  const { user, question } = props;

  return props.props.mode === "arcade" ? (
    <ArcadeAnswer user={user} question={question} props={props.props} />
  ) : (
    <TrainingAnswer
      props={props.props}
      user={user}
      question={question}
      nextAction={() => {
        props.props.handleClose("next");
      }}
      closeAction={() => {
        props.props.handleClose("exit");
      }}
    />
  );
};

const QuestionAnswer = props => {
  const { user, question } = props;
  return (
    <Backdrop
      transitionDuration={0}
      className={props.classes.backdrop}
      open={props.open}
    >
      <Card className={props.classes.cardAnswer}>
        <RenderAnswer props={props} user={user} question={question} />
      </Card>
    </Backdrop>
  );
};

export default QuestionAnswer;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import ButtonPanel from "../ButtonPanel/dark";
import QuestionTitle from "../QuestionTitle";
import utils from "../../services/utils";

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 400,
    textAlign: "justify",
    color: "#FAFAFA"
  }
}));

const Question = props => {
  const classes = useStyles();

  return (
    <>
      {props.loading && (
        <>
          <Skeleton animation="wave" /> <Skeleton animation="wave" />{" "}
          <Skeleton animation="wave" /> <Skeleton animation="wave" />
          <Skeleton animation="wave" /> <Skeleton animation="wave" />{" "}
          <Skeleton animation="wave" /> <Skeleton animation="wave" />
        </>
      )}
      {!props.loading && (
        <>
          <Typography className={classes.title}>
            {utils.breakLines(props.value.enunciado)}
          </Typography>
          <QuestionTitle text={props.value.descricao} />
          <ButtonPanel
            user={props.user}
            classes={props.classes}
            question={props.value}
            showResult={props.showResult}
            handleClose={props.handleClose}
            panelinha={props.panelinha}
            questionId={props.questionId}
          />
        </>
      )}
    </>
  );
};

export default Question;

import React, { Component } from "react";

import PropTypes from "prop-types";

import validate from "validate.js";
import moment from "moment";

import { withStyles } from "@material-ui/core/styles";

import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import Hidden from "@material-ui/core/Hidden";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

import CloseIcon from "@material-ui/icons/Close";
import PhotoIcon from "@material-ui/icons/Photo";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import PersonIcon from "@material-ui/icons/Person";
import EditIcon from "@material-ui/icons/Edit";
import EmailIcon from "@material-ui/icons/Email";
import WarningIcon from "@material-ui/icons/Warning";
import CheckIcon from "@material-ui/icons/Check";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

import constraints from "../../constraints";
import authentication from "../../services/authentication";

import httpClient from "../../services/httpClient";

const styles = theme => ({
  dialogContent: {
    paddingTop: theme.spacing(2)
  },

  badge: {
    top: theme.spacing(2),
    right: -theme.spacing(2)
  },

  loadingBadge: {
    top: "50%",
    right: "50%"
  },

  avatar: {
    marginRight: "auto",
    marginLeft: "auto",

    width: theme.spacing(14),
    height: theme.spacing(14)
  },

  nameInitials: {
    cursor: "default"
  },

  personIcon: {
    fontSize: theme.spacing(7)
  },

  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),

    minHeight: "initial"
  }
});

const initialState = {
  profileCompletion: 0,
  securityRating: 0,
  showingField: "",
  avatar: null,
  avatarUrl: "",
  firstName: "",
  lastName: "",
  cpf: "",
  phoneNumber: "",
  username: "",
  emailAddress: "",
  performingAction: false,
  loadingAvatar: false,
  sentVerificationEmail: false,
  errors: null,
  answers: null,
  percent: 0
};

function isValidCPF(cpf) {
  if (typeof cpf !== "string") return false;
  cpf = cpf.replace(/[^\d]+/g, "");
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
  cpf = cpf.split("");
  const validator = cpf
    .filter((digit, index, array) => index >= array.length - 2 && digit)
    .map(el => +el);
  const toValidate = pop =>
    cpf
      .filter((digit, index, array) => index < array.length - pop && digit)
      .map(el => +el);
  const rest = (count, pop) =>
    ((toValidate(pop).reduce((soma, el, i) => soma + el * (count - i), 0) *
      10) %
      11) %
    10;
  return !(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1]);
}

function isValidPhone(phone) {
  const exp = /^[1-9]([0-9]{10}$)/;
  return exp.test(phone);
}

class AccountTab extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  getNameInitialsOrIcon = () => {
    const { user } = this.props;

    if (!user) {
      return null;
    }

    const { classes, userData } = this.props;

    if (!userData) {
      return <PersonIcon className={classes.personIcon} />;
    }

    const nameInitials = authentication.getNameInitials({
      ...user,
      ...userData
    });

    if (nameInitials) {
      return (
        <Typography className={classes.nameInitials} variant="h2">
          {nameInitials}
        </Typography>
      );
    }

    return <PersonIcon className={classes.personIcon} />;
  };

  uploadAvatar = () => {
    const { avatar } = this.state;

    if (!avatar) {
      return;
    }

    this.setState(
      {
        performingAction: true,
        loadingAvatar: true
      },
      () => {
        authentication
          .changeAvatar(avatar)
          .then(value => {
            const { user, userData } = this.props;

            this.setState({
              profileCompletion: authentication.getProfileCompletion({
                ...user,
                ...userData
              })
            });
          })
          .catch(reason => {
            const code = reason.code;
            const message = reason.message;

            switch (code) {
              default:
                this.props.openSnackbar(message);
                return;
            }
          })
          .finally(() => {
            this.setState({
              performingAction: false,
              loadingAvatar: false,
              avatar: null,
              avatarUrl: ""
            });
          });
      }
    );
  };

  removeAvatar = () => {
    const { user } = this.props;

    const { avatar, avatarUrl } = this.state;

    if (!user.photoURL && !avatar && !avatarUrl) {
      return;
    }

    if (
      (!user.photoURL && avatar && avatarUrl) ||
      (user.photoURL && avatar && avatarUrl)
    ) {
      URL.revokeObjectURL(avatarUrl);

      this.setState({
        avatar: null,
        avatarUrl: ""
      });
    } else if (user.photoURL && !avatar && !avatarUrl) {
      this.setState(
        {
          performingAction: true,
          loadingAvatar: true
        },
        () => {
          authentication
            .removeAvatar()
            .then(value => {
              const { user, userData } = this.props;

              this.setState({
                profileCompletion: authentication.getProfileCompletion({
                  ...user,
                  ...userData
                })
              });
            })
            .catch(reason => {
              const code = reason.code;
              const message = reason.message;

              switch (code) {
                default:
                  this.props.openSnackbar(message);
                  return;
              }
            })
            .finally(() => {
              this.setState({
                performingAction: false,
                loadingAvatar: false
              });
            });
        }
      );
    }
  };

  showField = fieldId => {
    if (!fieldId) {
      return;
    }

    this.setState({
      showingField: fieldId
    });
  };

  hideFields = callback => {
    this.setState(
      {
        showingField: "",
        firstName: "",
        lastName: "",
        username: "",
        cpf: "",
        phoneNumber: "",
        emailAddress: "",
        errors: null
      },
      () => {
        if (callback && typeof callback === "function") {
          callback();
        }
      }
    );
  };

  changeFirstName = () => {
    const { firstName } = this.state;

    const errors = validate(
      {
        firstName: firstName
      },
      {
        firstName: constraints.firstName
      }
    );

    if (errors) {
      this.setState({
        errors: errors
      });

      return;
    }

    this.setState(
      {
        errors: null
      },
      () => {
        const { userData } = this.props;

        if (firstName === userData.firstName) {
          return;
        }

        this.setState(
          {
            performingAction: true
          },
          () => {
            authentication
              .changeFirstName(firstName)
              .then(() => {
                const { user, userData } = this.props;

                this.setState(
                  {
                    profileCompletion: authentication.getProfileCompletion({
                      ...user,
                      ...userData
                    })
                  },
                  () => {
                    this.hideFields();
                  }
                );
              })
              .catch(reason => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                  default:
                    this.props.openSnackbar(message);
                    return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false
                });
              });
          }
        );
      }
    );
  };

  changeLastName = () => {
    const { lastName } = this.state;

    const errors = validate(
      {
        lastName: lastName
      },
      {
        lastName: constraints.lastName
      }
    );

    if (errors) {
      this.setState({
        errors: errors
      });

      return;
    }

    this.setState(
      {
        errors: null
      },
      () => {
        const { userData } = this.props;

        if (lastName === userData.lastName) {
          return;
        }

        this.setState(
          {
            performingAction: true
          },
          () => {
            authentication
              .changeLastName(lastName)
              .then(() => {
                const { user, userData } = this.props;

                this.setState(
                  {
                    profileCompletion: authentication.getProfileCompletion({
                      ...user,
                      ...userData
                    })
                  },
                  () => {
                    this.hideFields();
                  }
                );
              })
              .catch(reason => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                  default:
                    this.props.openSnackbar(message);
                    return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false
                });
              });
          }
        );
      }
    );
  };

  changeUsername = () => {
    const { username } = this.state;

    const errors = validate(
      {
        username: username
      },
      {
        username: constraints.username
      }
    );

    if (errors) {
      this.setState({
        errors: errors
      });

      return;
    }

    this.setState(
      {
        errors: null
      },
      () => {
        const { userData } = this.props;

        if (username === userData.username) {
          return;
        }

        this.setState(
          {
            performingAction: true
          },
          () => {
            authentication
              .changeUsername(username)
              .then(() => {
                const { user, userData } = this.props;

                this.setState(
                  {
                    profileCompletion: authentication.getProfileCompletion({
                      ...user,
                      ...userData
                    })
                  },
                  () => {
                    this.hideFields();
                  }
                );
              })
              .catch(reason => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                  default:
                    this.props.openSnackbar(message);
                    return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false
                });
              });
          }
        );
      }
    );
  };

  changeCPF = () => {
    const { cpf } = this.state;

    let errors = validate(
      {
        cpf
      },
      {
        cpf: constraints.cpf
      }
    );

    if (!isValidCPF(cpf)) {
      if (!errors) {
        errors = {
          cpf: ["CPF inválido!"]
        };
      } else {
        errors.cpf.push("CPF inválido!");
      }
    }

    if (errors) {
      this.setState({
        errors
      });

      return;
    }

    this.setState(
      {
        errors: null
      },
      () => {
        const { userData } = this.props;

        if (cpf === userData.cpf) {
          return;
        }

        this.setState(
          {
            performingAction: true
          },
          () => {
            authentication
              .changeCPF(cpf)
              .then(() => {
                const { user, userData } = this.props;

                this.setState(
                  {
                    profileCompletion: authentication.getProfileCompletion({
                      ...user,
                      ...userData
                    })
                  },
                  () => {
                    this.hideFields();
                  }
                );
              })
              .catch(reason => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                  default:
                    this.props.openSnackbar(message);
                    return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false
                });
              });
          }
        );
      }
    );
  };

  changePhoneNumber = () => {
    const { phoneNumber } = this.state;

    let errors = validate(
      {
        phoneNumber
      },
      {
        phoneNumber: constraints.phoneNumber
      }
    );

    if (!isValidPhone(phoneNumber)) {
      if (!errors) {
        errors = {
          phoneNumber: ["Celular inválido!"]
        };
      } else {
        errors.phoneNumber.push("Celular inválido!");
      }
    }

    if (errors) {
      this.setState({
        errors
      });

      return;
    }

    this.setState(
      {
        errors: null
      },
      () => {
        const { userData } = this.props;

        if (phoneNumber === userData.phoneNumber) {
          return;
        }

        this.setState(
          {
            performingAction: true
          },
          () => {
            authentication
              .changePhoneNumber(phoneNumber)
              .then(() => {
                const { user, userData } = this.props;

                this.setState(
                  {
                    profileCompletion: authentication.getProfileCompletion({
                      ...user,
                      ...userData
                    })
                  },
                  () => {
                    this.hideFields();
                  }
                );
              })
              .catch(reason => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                  default:
                    this.props.openSnackbar(message);
                    return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false
                });
              });
          }
        );
      }
    );
  };

  changeEmailAddress = () => {
    const { emailAddress } = this.state;

    const errors = validate(
      {
        emailAddress: emailAddress
      },
      {
        emailAddress: constraints.emailAddress
      }
    );

    if (errors) {
      this.setState({
        errors: errors
      });

      return;
    }

    this.setState(
      {
        errors: null
      },
      () => {
        const { user } = this.props;

        if (emailAddress === user.email) {
          return;
        }

        this.setState(
          {
            performingAction: true
          },
          () => {
            authentication
              .changeEmailAddress(emailAddress)
              .then(() => {
                const { user, userData } = this.props;

                this.setState(
                  {
                    profileCompletion: authentication.getProfileCompletion({
                      ...user,
                      ...userData
                    })
                  },
                  () => {
                    this.hideFields();
                  }
                );
              })
              .catch(reason => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                  default:
                    this.props.openSnackbar(message);
                    return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false
                });
              });
          }
        );
      }
    );
  };

  verifyEmailAddress = () => {
    this.setState(
      {
        performingAction: true
      },
      () => {
        authentication
          .verifyEmailAddress()
          .then(() => {
            this.setState(
              {
                sentVerificationEmail: true
              },
              () => {
                this.props.openSnackbar("E-mail de verificação enviado");
              }
            );
          })
          .catch(reason => {
            const code = reason.code;
            const message = reason.message;

            switch (code) {
              default:
                this.props.openSnackbar(message);
                return;
            }
          })
          .finally(() => {
            this.setState({
              performingAction: false
            });
          });
      }
    );
  };

  changeField = fieldId => {
    switch (fieldId) {
      case "first-name":
        this.changeFirstName();
        return;

      case "last-name":
        this.changeLastName();
        return;

      case "username":
        this.changeUsername();
        return;

      case "cpf":
        this.changeCPF();
        return;

      case "phoneNumber":
        this.changePhoneNumber();
        return;

      case "email-address":
        this.changeEmailAddress();
        return;

      default:
        return;
    }
  };

  handleKeyDown = (event, fieldId) => {
    if (!event || !fieldId) {
      return;
    }

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    const key = event.key;

    if (!key) {
      return;
    }

    if (key === "Escape") {
      this.hideFields();
    } else if (key === "Enter") {
      this.changeField(fieldId);
    }
  };

  handleAvatarChange = event => {
    if (!event) {
      return;
    }

    const files = event.target.files;

    if (!files) {
      return;
    }

    const avatar = files[0];

    if (!avatar) {
      return;
    }

    const fileTypes = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/svg+xml"
    ];

    if (!fileTypes.includes(avatar.type)) {
      return;
    }

    if (avatar.size > 20 * 1024 * 1024) {
      return;
    }

    this.setState({
      avatar: avatar,
      avatarUrl: URL.createObjectURL(avatar)
    });
  };

  handleFirstNameChange = event => {
    if (!event) {
      return;
    }

    const firstName = event.target.value;

    this.setState({
      firstName: firstName
    });
  };

  handleLastNameChange = event => {
    if (!event) {
      return;
    }

    const lastName = event.target.value;

    this.setState({
      lastName: lastName
    });
  };

  handleUsernameChange = event => {
    if (!event) {
      return;
    }

    const username = event.target.value.substr(0, 3);

    this.setState({
      username: username
    });
  };

  handleCPFChange = event => {
    if (!event) {
      return;
    }

    const cpf = event.target.value;

    this.setState({
      cpf: cpf
    });
  };

  handlePhoneChange = event => {
    if (!event) {
      return;
    }

    const phoneNumber = event.target.value;

    this.setState({
      phoneNumber: phoneNumber
    });
  };

  handleEmailAddressChange = event => {
    if (!event) {
      return;
    }

    const emailAddress = event.target.value;

    this.setState({
      emailAddress: emailAddress
    });
  };

  render() {
    // Styling
    const { classes } = this.props;

    // Properties
    const { user, userData } = this.props;

    const {
      profileCompletion,
      securityRating,
      showingField,
      performingAction,
      loadingAvatar,
      avatar,
      avatarUrl,
      firstName,
      lastName,
      username,
      cpf,
      phoneNumber,
      emailAddress,
      sentVerificationEmail,
      errors,
      answers,
      percent
    } = this.state;

    const hasFirstName = userData && userData.firstName;
    const hasLastName = userData && userData.lastName;
    const hasUsername = userData && userData.username;
    const hasCPF = userData && userData.cpf;
    const hasPhoneNumber = userData && userData.phoneNumber;

    return (
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Box mb={2}>
          <Hidden xsDown>
            <Grid alignItems="center" container>
              <Grid item xs>
                <Box textAlign="center">
                  <Box mb={1.5}>
                    {avatar && avatarUrl && (
                      <Badge
                        classes={{ badge: classes.badge }}
                        badgeContent={
                          <Tooltip title="Remove">
                            <Fab
                              classes={{ sizeSmall: classes.small }}
                              color="secondary"
                              disabled={performingAction}
                              size="small"
                              onClick={this.removeAvatar}
                            >
                              <CloseIcon fontSize="small" />
                            </Fab>
                          </Tooltip>
                        }
                      >
                        {loadingAvatar && (
                          <Badge
                            classes={{ badge: classes.loadingBadge }}
                            badgeContent={
                              <Fade
                                style={{ transitionDelay: "1s" }}
                                in={loadingAvatar}
                                unmountOnExit
                              >
                                <CircularProgress size={120} thickness={1.8} />
                              </Fade>
                            }
                          >
                            <Avatar
                              className={classes.avatar}
                              alt="Avatar"
                              src={avatarUrl}
                            />
                          </Badge>
                        )}

                        {!loadingAvatar && (
                          <Avatar
                            className={classes.avatar}
                            alt="Avatar"
                            src={avatarUrl}
                          />
                        )}
                      </Badge>
                    )}

                    {!avatar && !avatarUrl && (
                      <>
                        {user.photoURL && (
                          <Badge
                            classes={{ badge: classes.badge }}
                            badgeContent={
                              <Tooltip title="Remove">
                                <Fab
                                  classes={{ sizeSmall: classes.small }}
                                  color="secondary"
                                  disabled={performingAction}
                                  size="small"
                                  onClick={this.removeAvatar}
                                >
                                  <CloseIcon fontSize="small" />
                                </Fab>
                              </Tooltip>
                            }
                          >
                            {loadingAvatar && (
                              <Badge
                                classes={{ badge: classes.loadingBadge }}
                                badgeContent={
                                  <Fade
                                    style={{ transitionDelay: "1s" }}
                                    in={loadingAvatar}
                                    unmountOnExit
                                  >
                                    <CircularProgress
                                      size={120}
                                      thickness={1.8}
                                    />
                                  </Fade>
                                }
                              >
                                <Avatar
                                  className={classes.avatar}
                                  alt="Avatar"
                                  src={user.photoURL}
                                />
                              </Badge>
                            )}

                            {!loadingAvatar && (
                              <Avatar
                                className={classes.avatar}
                                alt="Avatar"
                                src={user.photoURL}
                              />
                            )}
                          </Badge>
                        )}

                        {!user.photoURL && (
                          <>
                            {loadingAvatar && (
                              <Badge
                                classes={{ badge: classes.loadingBadge }}
                                badgeContent={
                                  <Fade
                                    style={{ transitionDelay: "1s" }}
                                    in={loadingAvatar}
                                    unmountOnExit
                                  >
                                    <CircularProgress
                                      size={120}
                                      thickness={1.8}
                                    />
                                  </Fade>
                                }
                              >
                                <Avatar className={classes.avatar} alt="Avatar">
                                  {this.getNameInitialsOrIcon()}
                                </Avatar>
                              </Badge>
                            )}

                            {!loadingAvatar && (
                              <Avatar className={classes.avatar} alt="Avatar">
                                {this.getNameInitialsOrIcon()}
                              </Avatar>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Box>

                  {avatar && avatarUrl && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      startIcon={<CloudUploadIcon />}
                      variant="contained"
                      onClick={this.uploadAvatar}
                    >
                      SALVAR
                    </Button>
                  )}

                  {!avatar && !avatarUrl && (
                    <>
                      <input
                        id="avatar-input"
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={this.handleAvatarChange}
                      />

                      <label htmlFor="avatar-input">
                        <Button
                          color="primary"
                          component="span"
                          disabled={performingAction}
                          startIcon={<PhotoIcon />}
                          variant="contained"
                        >
                          Alterar...
                        </Button>
                      </label>
                    </>
                  )}
                </Box>
              </Grid>

              <Grid item xs>
                <Box textAlign="center">
                  <Typography variant="body1">Questões respondidas</Typography>
                  <Typography color="yellow" variant="h5">
                    {answers}
                  </Typography>

                  {/* {profileCompletion === 0 && (
                    <Typography color="error" variant="h5">
                      {profileCompletion}%
                    </Typography>
                  )}

                  {profileCompletion === 100 && (
                    <Typography color="primary" variant="h5">
                      {profileCompletion}%
                    </Typography>
                  )}

                  {profileCompletion !== 0 && profileCompletion !== 100 && (
                    <Typography color="secondary" variant="h5">
                      {profileCompletion}%
                    </Typography>
                  )} */}
                </Box>
              </Grid>

              <Grid item xs>
                <Box textAlign="center">
                  <Typography variant="body1">Percentual de acerto</Typography>

                  {percent < 50 && (
                    <Typography color="error" variant="h5">
                      {percent}%
                    </Typography>
                  )}

                  {percent >= 50 && percent < 90 && (
                    <Typography color="yellow" variant="h5">
                      {percent}%
                    </Typography>
                  )}

                  {percent >= 90 && percent <= 100 && (
                    <Typography color="green" variant="h5">
                      {percent}%
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Hidden>

          <Hidden smUp>
            <Box textAlign="center" mb={3}>
              <Box mb={1.5}>
                {avatar && avatarUrl && (
                  <Badge
                    classes={{ badge: classes.badge }}
                    badgeContent={
                      <Tooltip title="Remove">
                        <Fab
                          classes={{ sizeSmall: classes.small }}
                          color="secondary"
                          disabled={performingAction}
                          size="small"
                          onClick={this.removeAvatar}
                        >
                          <CloseIcon fontSize="small" />
                        </Fab>
                      </Tooltip>
                    }
                  >
                    {loadingAvatar && (
                      <Badge
                        classes={{ badge: classes.loadingBadge }}
                        badgeContent={
                          <Fade
                            style={{ transitionDelay: "1s" }}
                            in={loadingAvatar}
                            unmountOnExit
                          >
                            <CircularProgress size={120} thickness={1.8} />
                          </Fade>
                        }
                      >
                        <Avatar
                          className={classes.avatar}
                          alt="Avatar"
                          src={avatarUrl}
                        />
                      </Badge>
                    )}

                    {!loadingAvatar && (
                      <Avatar
                        className={classes.avatar}
                        alt="Avatar"
                        src={avatarUrl}
                      />
                    )}
                  </Badge>
                )}

                {!avatar && !avatarUrl && (
                  <>
                    {user.photoURL && (
                      <Badge
                        classes={{ badge: classes.badge }}
                        badgeContent={
                          <Tooltip title="Remove">
                            <Fab
                              classes={{ sizeSmall: classes.small }}
                              color="secondary"
                              disabled={performingAction}
                              size="small"
                              onClick={this.removeAvatar}
                            >
                              <CloseIcon fontSize="small" />
                            </Fab>
                          </Tooltip>
                        }
                      >
                        {loadingAvatar && (
                          <Badge
                            classes={{ badge: classes.loadingBadge }}
                            badgeContent={
                              <CircularProgress size={120} thickness={1.8} />
                            }
                          >
                            <Avatar
                              className={classes.avatar}
                              alt="Avatar"
                              src={user.photoURL}
                            />
                          </Badge>
                        )}

                        {!loadingAvatar && (
                          <Avatar
                            className={classes.avatar}
                            alt="Avatar"
                            src={user.photoURL}
                          />
                        )}
                      </Badge>
                    )}

                    {!user.photoURL && (
                      <>
                        {loadingAvatar && (
                          <Badge
                            classes={{ badge: classes.loadingBadge }}
                            badgeContent={
                              <Fade
                                style={{ transitionDelay: "1s" }}
                                in={loadingAvatar}
                                unmountOnExit
                              >
                                <CircularProgress size={120} thickness={1.8} />
                              </Fade>
                            }
                          >
                            <Avatar className={classes.avatar} alt="Avatar">
                              {this.getNameInitialsOrIcon()}
                            </Avatar>
                          </Badge>
                        )}

                        {!loadingAvatar && (
                          <Avatar className={classes.avatar} alt="Avatar">
                            {this.getNameInitialsOrIcon()}
                          </Avatar>
                        )}
                      </>
                    )}
                  </>
                )}
              </Box>

              {avatar && avatarUrl && (
                <Button
                  color="primary"
                  disabled={performingAction}
                  startIcon={<CloudUploadIcon />}
                  variant="contained"
                  onClick={this.uploadAvatar}
                >
                  SALVAR
                </Button>
              )}

              {!avatar && !avatarUrl && (
                <>
                  <input
                    id="avatar-input"
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={this.handleAvatarChange}
                  />

                  <label htmlFor="avatar-input">
                    <Button
                      color="primary"
                      component="span"
                      disabled={performingAction}
                      startIcon={<PhotoIcon />}
                      variant="contained"
                    >
                      Alterar...
                    </Button>
                  </label>
                </>
              )}
            </Box>

            <Grid container>
              <Grid item xs>
                <Box textAlign="center">
                  <Typography variant="body1">Questões respondidas</Typography>
                  <Typography color="yellow" variant="h5">
                    {answers}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs>
                <Box textAlign="center">
                  <Typography variant="body1">Percentual de acerto</Typography>

                  {percent < 50 && (
                    <Typography color="error" variant="h5">
                      {percent}%
                    </Typography>
                  )}

                  {percent >= 50 && percent < 90 && (
                    <Typography color="yellow" variant="h5">
                      {percent}%
                    </Typography>
                  )}

                  {percent >= 90 && percent <= 100 && (
                    <Typography color="green" variant="h5">
                      {percent}%
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Hidden>
        </Box>

        <List disablePadding>
          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
            </Hidden>

            {!hasFirstName && (
              <ListItemIcon>
                <Tooltip title="No first name">
                  <WarningIcon color="error" />
                </Tooltip>
              </ListItemIcon>
            )}

            {showingField === "first-name" && (
              <TextField
                autoComplete="given-name"
                autoFocus
                disabled={performingAction}
                error={!!(errors && errors.firstName)}
                fullWidth
                helperText={
                  errors && errors.firstName
                    ? errors.firstName[0]
                    : "Finalize com Enter"
                }
                label="Nome"
                placeholder={hasFirstName && userData.firstName}
                required
                type="text"
                value={firstName}
                variant="filled"
                onBlur={this.hideFields}
                onKeyDown={event => this.handleKeyDown(event, "first-name")}
                onChange={this.handleFirstNameChange}
              />
            )}

            {showingField !== "first-name" && (
              <>
                <ListItemText
                  primary="Nome"
                  secondary={
                    hasFirstName ? userData.firstName : "Não informado :("
                  }
                />

                <ListItemSecondaryAction>
                  {hasFirstName && (
                    <Tooltip title="Change">
                      <div>
                        <IconButton
                          disabled={performingAction}
                          onClick={() => this.showField("first-name")}
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  {!hasFirstName && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      variant="contained"
                      onClick={() => this.showField("first-name")}
                    >
                      Incluir
                    </Button>
                  )}
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>

          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
            </Hidden>

            {!hasLastName && (
              <ListItemIcon>
                <Tooltip title="Sobrenome não informado.">
                  <WarningIcon color="error" />
                </Tooltip>
              </ListItemIcon>
            )}

            {showingField === "last-name" && (
              <TextField
                autoComplete="family-name"
                autoFocus
                disabled={performingAction}
                error={!!(errors && errors.lastName)}
                fullWidth
                helperText={
                  errors && errors.lastName
                    ? errors.lastName[0]
                    : "Finalize com Enter"
                }
                label="Sobrenome"
                placeholder={hasLastName && userData.lastName}
                required
                type="text"
                value={lastName}
                variant="filled"
                onBlur={this.hideFields}
                onKeyDown={event => this.handleKeyDown(event, "last-name")}
                onChange={this.handleLastNameChange}
              />
            )}

            {showingField !== "last-name" && (
              <>
                <ListItemText
                  primary="Sobrenome"
                  secondary={
                    hasLastName ? userData.lastName : "Sobrenome não informado"
                  }
                />

                <ListItemSecondaryAction>
                  {hasLastName && (
                    <Tooltip title="Alterar">
                      <div>
                        <IconButton
                          disabled={performingAction}
                          onClick={() => this.showField("last-name")}
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  {!hasLastName && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      variant="contained"
                      onClick={() => this.showField("last-name")}
                    >
                      Incluir
                    </Button>
                  )}
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>

          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
            </Hidden>

            {!hasCPF && (
              <ListItemIcon>
                <Tooltip title="CPF não informado.">
                  <WarningIcon color="error" />
                </Tooltip>
              </ListItemIcon>
            )}

            {showingField === "cpf" && (
              <TextField
                autoComplete="cpf"
                autoFocus
                disabled={performingAction}
                error={!!(errors && errors.cpf)}
                fullWidth
                helperText={
                  errors && errors.cpf ? errors.cpf[0] : "Finalize com Enter"
                }
                label="CPF"
                placeholder={hasCPF && userData.cpf}
                required
                type="text"
                value={cpf}
                variant="filled"
                onBlur={this.hideFields}
                onKeyDown={event => this.handleKeyDown(event, "cpf")}
                onChange={this.handleCPFChange}
              />
            )}

            {showingField !== "cpf" && (
              <>
                <ListItemText
                  primary="CPF"
                  secondary={hasCPF ? userData.cpf : "CPF não informado"}
                />

                <ListItemSecondaryAction>
                  {hasCPF && (
                    <Tooltip title="Alterar">
                      <div>
                        <IconButton
                          disabled={performingAction}
                          onClick={() => this.showField("cpf")}
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  {!hasCPF && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      variant="contained"
                      onClick={() => this.showField("cpf")}
                    >
                      Incluir
                    </Button>
                  )}
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>

          {/* Início telefone */}

          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
            </Hidden>

            {!hasPhoneNumber && (
              <ListItemIcon>
                <Tooltip title="Celular não informado.">
                  <WarningIcon color="error" />
                </Tooltip>
              </ListItemIcon>
            )}

            {showingField === "phoneNumber" && (
              <TextField
                autoComplete="phonenumber"
                autoFocus
                disabled={performingAction}
                error={!!(errors && errors.phoneNumber)}
                fullWidth
                helperText={
                  errors && errors.phoneNumber
                    ? errors.phoneNumber[0]
                    : "Finalize com Enter"
                }
                label="Celular"
                placeholder={hasPhoneNumber && userData.phoneNumber}
                required
                type="text"
                value={phoneNumber}
                variant="filled"
                onBlur={this.hideFields}
                onKeyDown={event => this.handleKeyDown(event, "phoneNumber")}
                onChange={this.handlePhoneChange}
              />
            )}

            {showingField !== "phoneNumber" && (
              <>
                <ListItemText
                  primary="Celular"
                  secondary={
                    hasPhoneNumber
                      ? userData.phoneNumber
                      : "Celular não informado"
                  }
                />

                <ListItemSecondaryAction>
                  {hasPhoneNumber && (
                    <Tooltip title="Alterar">
                      <div>
                        <IconButton
                          disabled={performingAction}
                          onClick={() => this.showField("phoneNumber")}
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  {!hasPhoneNumber && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      variant="contained"
                      onClick={() => this.showField("phoneNumber")}
                    >
                      Incluir
                    </Button>
                  )}
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>

          {/* Fim telefone */}

          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
            </Hidden>

            {!hasUsername && (
              <ListItemIcon>
                <Tooltip title="Sem nome pro Ranking">
                  <WarningIcon color="error" />
                </Tooltip>
              </ListItemIcon>
            )}

            {showingField === "username" && (
              <TextField
                autoComplete="username"
                autoFocus
                disabled={performingAction}
                error={!!(errors && errors.username)}
                fullWidth
                helperText={
                  errors && errors.username
                    ? errors.username[0]
                    : "Finalize com Enter"
                }
                label="Nome do ranking"
                placeholder={hasUsername && userData.username}
                max
                required
                type="text"
                value={username}
                variant="filled"
                onBlur={this.hideFields}
                onKeyDown={event => this.handleKeyDown(event, "username")}
                onChange={this.handleUsernameChange}
                onInput={e => {
                  e.target.value = e.target.value.toString().slice(0, 3);
                }}
              />
            )}

            {showingField !== "username" && (
              <>
                <ListItemText
                  primary="Nome do ranking"
                  secondary={
                    hasUsername ? userData.username : "Não informado :("
                  }
                />

                <ListItemSecondaryAction>
                  {hasUsername && (
                    <Tooltip title="Change">
                      <div>
                        <IconButton
                          disabled={performingAction}
                          onClick={() => this.showField("username")}
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  {!hasUsername && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      variant="contained"
                      onClick={() => this.showField("username")}
                    >
                      Incluir
                    </Button>
                  )}
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>

          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
            </Hidden>

            {user.email && (
              <ListItemIcon>
                <>
                  {user.emailVerified && (
                    <Tooltip title="Verificado">
                      <CheckIcon color="primary" />
                    </Tooltip>
                  )}
                </>
              </ListItemIcon>
            )}

            {!user.email && (
              <ListItemIcon>
                <Tooltip title="Sem e-mail">
                  <WarningIcon color="error" />
                </Tooltip>
              </ListItemIcon>
            )}

            {showingField === "email-address" && (
              <TextField
                autoComplete="email"
                autoFocus
                disabled={true}
                error={!!(errors && errors.emailAddress)}
                fullWidth
                helperText={
                  errors && errors.emailAddress
                    ? errors.emailAddress[0]
                    : "Aperte Enter to change your e-mail address"
                }
                label="E-mail address"
                placeholder={user.email}
                required
                type="email"
                value={emailAddress}
                variant="filled"
              />
            )}

            {showingField !== "email-address" && (
              <>
                <ListItemText
                  primary="E-mail address"
                  secondary={
                    user.email ? user.email : "You don’t have an e-mail address"
                  }
                />

                {user.email && !user.emailVerified && (
                  <Box clone mr={7}>
                    <ListItemSecondaryAction>
                      <Tooltip title="Verify">
                        <div>
                          <IconButton
                            color="secondary"
                            disabled={performingAction || sentVerificationEmail}
                            onClick={this.verifyEmailAddress}
                          >
                            <CheckIcon />
                          </IconButton>
                        </div>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </Box>
                )}

                {/* <ListItemSecondaryAction>
                  {user.email && (
                    <Tooltip title="Change">
                      <div>
                        <IconButton
                          disabled={performingAction}
                          onClick={() => this.showField("email-address")}
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  {!user.email && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      variant="contained"
                      onClick={() => this.showField("email-address")}
                    >
                      +
                    </Button>
                  )}
                </ListItemSecondaryAction> */}
              </>
            )}
          </ListItem>

          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <AccessTimeIcon />
              </ListItemIcon>
            </Hidden>

            <Hidden xsDown>
              <ListItemText
                primary="Logado em"
                secondary={moment(user.metadata.lastSignInTime).format(
                  "DD/MM/YYYY - HH:mm:ss"
                )}
              />
            </Hidden>

            <Hidden smUp>
              <ListItemText
                primary="Logado em "
                secondary={moment(user.metadata.lastSignInTime).format(
                  "DD/MM/YYYY - HH:mm:ss"
                )}
              />
            </Hidden>
          </ListItem>

          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <AccessTimeIcon />
              </ListItemIcon>
            </Hidden>

            <Hidden xsDown>
              <ListItemText
                primary="Cadastrado desde"
                secondary={moment(user.metadata.creationTime).format(
                  "DD/MM/YYYY - HH:mm:ss"
                )}
              />
            </Hidden>

            <Hidden smUp>
              <ListItemText
                primary="Cadastrado desde "
                secondary={moment(user.metadata.creationTime).format(
                  "DD/MM/YYYY - HH:mm:ss"
                )}
              />
            </Hidden>
          </ListItem>

          {/* <Box mt={1} mb={1}>
            <Divider light />
          </Box>

          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <DeleteForeverIcon />
              </ListItemIcon>
            </Hidden>

            <ListItemText
              primary="Delete account"
              secondary="Accounts can’t be recovered"
            />

            <ListItemSecondaryAction>
              <Button
                color="secondary"
                disabled={performingAction}
                variant="contained"
                onClick={onDeleteAccountClick}
              >
                Delete
              </Button>
            </ListItemSecondaryAction>
          </ListItem> */}
        </List>
      </DialogContent>
    );
  }

  componentDidMount() {
    const { user } = this.props;

    /*this.setState({
      profileCompletion: authentication.getProfileCompletion({
        ...user,
        ...userData
      }),
      securityRating: authentication.getSecurityRating(user, userData)
    });*/

    const answers = result =>
      (result[0] && result[0].value) + (result[1] && result[1].value);
    const percent = result =>
      result[0] && result[1]
        ? Math.round(
            (result.find(x => x.id === "sucess").value / answers(result)) * 100
          )
        : 0;

    httpClient.getUserStats(user.uid).then(result => {
      this.setState({
        answers: isNaN(answers(result.data)) ? 0 : answers(result.data),
        percent: percent(result.data)
      });
    });
  }

  componentWillUnmount() {
    const { avatarUrl } = this.state;

    if (avatarUrl) {
      URL.revokeObjectURL(avatarUrl);

      this.setState({
        avatarUrl: ""
      });
    }
  }
}

AccountTab.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Properties
  user: PropTypes.object.isRequired,
  userData: PropTypes.object,

  // Functions
  openSnackbar: PropTypes.func.isRequired,

  // Events
  onDeleteAccountClick: PropTypes.func.isRequired
};

export default withStyles(styles)(AccountTab);

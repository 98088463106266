import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  redBar: {
    backgroundColor: "#EB5757",
    width: 48,
    height: 8,
    marginBottom: 15
  }
}));

const QuestionCounter = ({ tag, count, totalQuestions, percent }) => {
  const classes = useStyles();
  const totalText = totalQuestions ? `/${totalQuestions}` : "";

  const questionTitle = () => {
    let qTitle = `Questão ${count + 1}`;
    if (tag === 'RECAP' && !isNaN(parseInt(percent))) {
      qTitle = `Questão ${count + 1} - ${parseInt(percent)}% de erro`;
    }
    return qTitle;
  };

  return (
    <>
      <Typography variant="h6">
        {tag === "RECAP" ? "Repescagem" : tag}
      </Typography>
      <Typography variant="h5">
        {questionTitle()}
        {totalText}
      </Typography>
      <div className={classes.redBar}>&nbsp;</div>
    </>
  );
};

export default QuestionCounter;

import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, TextField } from "@material-ui/core";
import ForumIcon from "@material-ui/icons/Forum";
import StarIcon from "@material-ui/icons/Star";
import PersonIcon from "@material-ui/icons/Person";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import httpClient from "../../services/httpClient";

const useStyles = makeStyles({
  commentBox: {
    backgroundColor: "#fafafa",
    borderRadius: 10,
    padding: 20,
    marginTop: 40,
    marginBottom: 40,
    color: "#333333"
  },
  commentItem: {
    width: "90%"
  },
  commentItemAnswer: {
    width: "90%",
    backgroundColor: "#f5eea2"
  },
  item: {
    background: "#ECECEC",
    border: "1px solid #ccc",
    borderRadius: 10,
    textAlign: "left",
    justifyContent: "flex-start",
    fontSize: 10,
    padding: 10,
    marginTop: 10,
    marginRight: 40
  },
  itemAnswer: {
    background: "#f7f2b7",
    border: "1px solid #ccc",
    borderRadius: 10,
    textAlign: "right",
    justifyContent: "flex-start",
    fontSize: 10,
    padding: 10,
    marginTop: 10,
    marginLeft: 40
  },
  commentText: {
    fontSize: 16
  },
  commentDate: {
    fontSize: 10,
    textAlign: "left",
    paddingLeft: 5,
    paddingRight: 5
  },
  commentDateAnswer: {
    fontSize: 10,
    textAlign: "right",
    paddingLeft: 5,
    paddingRight: 5
  },
  adminAnswer: {
    fontSize: 10,
    textAlign: "right",
    marginTop: 10,
    fontWeight: "bold"
  },
  starIcon: {
    fontSize: 10
  },
  confirmButton: {
    marginTop: 10,
    borderRadius: 20
  },
  center: {
    textAlign: "center"
  },
  textField: {
    marginTop: 20,
    width: "100%"
  },
  icon: {
    fontSize: 16
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    marginLeft: 10
  },

  cssLabel: {
    color: "gray",
    fontSize: 14
  },

  cssOutlinedInput: {
    color: "#333333",
    "&$cssFocused $notchedOutline": {
      borderColor: `#ECECEC !important`
    }
  },

  cssFocused: {},

  notchedOutline: {
    borderWidth: "1px",
    borderColor: "gray !important"
  },
  commentSent: {
    color: "green",
    textAlign: "center"
  }
});

const CommentBox = props => {
  const { questionId, user, panelinha } = props;
  const classes = useStyles();
  const [sent, setSent] = useState(false);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");

  useEffect(() => {
    if (questionId) {
      httpClient.getComments(questionId).then(result => {
        setComments(result.comments);
      });
    }
  }, [questionId]);

  const handleTextChange = e => {
    setNewComment(e.target.value);
  };

  const commitComment = () => {
    if (newComment) {
      httpClient.postComment(user, questionId, newComment).then(result => {
        setSent(true);
      });
    }
  };

  const parseAlias = user => {
    const name = user.username || user.email; //alis
    return name.substr(0, 3).toUpperCase();
  };

  const Comments = () => {
    if (comments && comments.length) {
      return (
        <Grid container>
          {comments.map(comment => {
            const admin = comment.user && comment.user.admin;
            const timestamp = new Date(comment.timestamp);
            if (admin) {
              return (
                <>
                  <Grid item xs={12} className={classes.itemAnswer}>
                    <Box display="flex" flexDirection="row-reverse">
                      <div>
                        {comment.text.split("\n").map((line, i) => (
                          <span className={classes.commentText} key={i}>
                            {line}
                            <br />
                          </span>
                        ))}
                        <div className={classes.adminAnswer}>
                          <StarIcon className={classes.starIcon} /> Resposta do
                          Nery
                        </div>
                      </div>
                    </Box>
                  </Grid>
                  <Grid className={classes.commentDateAnswer} item xs={12}>
                    {timestamp.toLocaleString("pt-BR")}
                  </Grid>
                </>
              );
            } else {
              return (
                <>
                  <Grid item xs={12} className={classes.item}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      className={classes.commentItem}
                    >
                      <div>
                        {comment.text.split("\n").map((line, i) => (
                          <span className={classes.commentText} key={i}>
                            {line}
                            <br />
                          </span>
                        ))}
                        <div className={classes.adminAnswer}>
                          <PersonIcon className={classes.starIcon} /> Enviado
                          por {parseAlias(comment.user)}
                        </div>
                      </div>
                    </Box>
                  </Grid>
                  <Grid className={classes.commentDate} item xs={12}>
                    {timestamp.toLocaleString("pt-BR")}
                  </Grid>
                </>
              );
            }
          })}
        </Grid>
      );
    }
    return "";
  };
  if (panelinha) {
    return (
      <Grid
        container
        alignContent="center"
        alignItems="center"
        className={classes.commentBox}
      >
        <Grid item xs={12}>
          <ForumIcon className={classes.icon} />
          <span className={classes.title}>Comentários</span>
          <Comments />
        </Grid>
        {!sent && (
          <>
            <Grid item xs={12}>
              <TextField
                id="comment-text"
                className={classes.textField}
                label="Tem algum comentário sobre a questão? Escreva aqui!"
                variant="outlined"
                multiline
                rows={3}
                onChange={handleTextChange}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline
                  },
                  inputMode: "numeric"
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.center}>
              <Button
                className={classes.confirmButton}
                variant="contained"
                color="secondary"
                onClick={() => commitComment(props)}
              >
                Comentar
              </Button>
            </Grid>
          </>
        )}
        {sent && (
          <Grid item xs={12} className={classes.commentSent}>
            <Grid
              container
              direction="row"
              alignItems="center"
              alignContent="center"
            >
              <Grid item>
                <CheckCircleIcon />
              </Grid>
              <Grid item>Comentário enviado com sucesso!</Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  } else {
    return "";
  }
};

export default CommentBox;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 660,
    margin: "0 auto",
    backgroundColor: "#ffffff",
    color: "#333333",
    borderRadius: 20,
    marginTop: 40,
    padding: 20
  },
  title: {
    margin: "20px 15px",
    fontSize: 22,
    fontWeight: "bold"
  },
  text: {
    margin: "15px",
    fontSize: 20
  },
  info: {
    fontSize: 16,
    lineHeight: 3
  },
  button: {
    borderRadius: 20,
    margin: 20,
    fontSize: 18
  },
  center: {
    textAlign: "center"
  },
  table: {
    color: "#333333"
  },
  right: {
    color: "green"
  },
  wrong: {
    color: "red"
  }
}));

const equal = (a, b) => (a && a.toLowerCase()) === (b && b.toLowerCase());

const SimuladoResult = props => {
  const { mock, setReview } = props;

  const rightCounter = mock.questions.filter(q => equal(q.selected, q.correta))
    .length;

  const classes = useStyles();

  const Feedback = () => {
    const results = mock.questions.map(q => ({
      id: q._id,
      correct: q.correta,
      selected: q.selected,
      status: equal(q.correta, q.selected)
    }));

    return (
      <TableContainer className={classes.table}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell key="correct" align="center">
                Questão
              </TableCell>
              <TableCell key="correct" align="center">
                Gabarito
              </TableCell>
              <TableCell key="select" align="center">
                Sua resposta
              </TableCell>
              <TableCell key="status" align="center">
                Acerto
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((row, index) => {
              return (
                <TableRow hover tabIndex={-1} key={row.id}>
                  <TableCell
                    key="correct"
                    align="center"
                    className={classes.table}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    key="correct"
                    align="center"
                    className={classes.table}
                  >
                    {row.correct.toUpperCase()}
                  </TableCell>
                  <TableCell
                    key="correct"
                    align="center"
                    className={classes.table}
                  >
                    {row.selected ? row.selected.toUpperCase() : "-"}
                  </TableCell>
                  <TableCell
                    key="correct"
                    align="center"
                    className={classes.table}
                  >
                    {row.status ? (
                      <CheckCircleIcon className={classes.right} />
                    ) : (
                      <HighlightOffIcon className={classes.wrong} />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const Info = () => (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <p className={classes.title}>{mock.title}</p>
          <p className={classes.text}>Parabéns! Você finalizou o simulado!</p>
          <p className={classes.text}>Tempo restante: {mock.remainingTime}</p>
          <p className={classes.text}>
            Média de tempo por questão:{" "}
            {parseInt(
              (mock.timeToComplete * 60 - mock.remainingSeconds) /
                mock.questions.length,
              10
            )}{" "}
            segundos
          </p>
          <p className={classes.text}>
            Você acertou um total de {rightCounter} questões!
          </p>
          <p className={classes.text}>Veja abaixo o seu gabarito:</p>
          <Feedback />
        </Grid>
        <Grid item xs={12} className={classes.center}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setReview(true)}
          >
            Revisar questões
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  return <Info />;
};

export default SimuladoResult;

import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Grid } from "@material-ui/core";

import validate from "validate.js";
import constraints from "../../constraints";
import caixaPretaLogo from "../../assets/lg-cp.png";
import googleIcon from "../../assets/icon-google-white.png";

import authentication from "../../services/authentication";
import httpClient from "../../services/httpClient";

import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 470,
    margin: "15px auto",
    textAlign: "center",
    color: "#333333",
    "& .Mui-disabled": {
      backgroundColor: "#BDBDBD",
      color: "#FAFAFA"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 20,
        borderColor: "#BDBDBD",
        color: "#333333"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 20,
        borderColor: "#BDBDBD",
        color: "#333333"
      },
      "& ::placeholder": {
        color: "#BDBDBD"
      }
    },
    "& .MuiInputLabel-outlined": {
      color: "#333333"
    },
    "& .MuiInputBase-input": {
      color: "#333333"
    }
  },
  logo: {
    width: "55%",
    margin: "0 auto",
    zIndex: 1,
    position: "relative",
    top: 40
  },
  loginGrid: {
    backgroundColor: "#FAFAFA",
    borderRadius: 20,
    padding: 20,
    paddingTop: 40,
    zIndex: 0,
    marginBottom: 20
  },
  inputFields: {
    color: "#333333"
  },
  btnLogin: {
    borderRadius: 20,
    backgroundColor: "#EB5757",
    color: "#FAFAFA",
    padding: "8px 16px",
    margin: 16
  },
  btnSignUp: {
    backgroundColor: "#FAFAFA",
    color: "#D73D32",
    borderRadius: 20,
    padding: "8px 16px"
  },
  footer: {
    color: "#FAFAFA",
    fontSize: "15px",
    textAlign: "center",
    marginTop: 40
  },
  ssoIcons: {
    backgroundColor: "#D73D32",
    borderRadius: 100,
    width: 48,
    padding: 10,
    cursor: "pointer"
  }
}));

const Login = props => {
  const classes = useStyles();
  // const [performingAction, setPerformingAction] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [errors, setErrors] = useState();
  const [totalQuestions, setTotalQuestions] = useState();
  useEffect(() => {
    httpClient.getTotalQuestions().then(result => {
      setTotalQuestions(result.data.count);
    });
  }, []);
  // const [loginState, setLoginState] = useState();
  const { onSignUpClick, openSnackbar } = props;

  const enableFields = () => {
    if (email && password) {
      //      setLoginState();
      return;
    }
    //  setLoginState("disabled");
    return;
  };

  setTimeout(() => enableFields, 200);

  const signIn = () => {
    const err = validate(
      {
        emailAddress: email,
        password: password
      },
      {
        emailAddress: constraints.emailAddress,
        password: constraints.password
      }
    );

    if (err) {
      setErrors(errors);
    } else {
      // setPerformingAction(true);
      setErrors(null);

      authentication
        .signIn(email, password)
        .then(user => {
          // dialogProps.onClose(() => {
          //   const displayName = user.displayName;
          //   const emailAddress = user.email;
          //   openSnackbar(`Logado como  ${displayName || emailAddress}`);
          // });
        })
        .catch(reason => {
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            case "auth/invalid-email":
            case "auth/user-disabled":
            case "auth/user-not-found":
            case "auth/wrong-password":
              openSnackbar(message);
              return;

            default:
              openSnackbar(message);
              return;
          }
        })
        .finally(() => {
          // setPerformingAction(false);
        });
    }
  };

  const signInWithAuthProvider = providerId => {
    //setPerformingAction(true);
    authentication
      .signInWithAuthProvider(providerId)
      .then(user => {
        // dialogProps.onClose(() => {
        //   const displayName = user.displayName;
        //   const emailAddress = user.email;
        //   //this.props.openSnackbar(
        //   //  `Logado como  ${displayName || emailAddress}`
        //   //);
        // });
      })
      .catch(reason => {
        const code = reason.code;
        const message = reason.message;

        switch (code) {
          case "auth/account-exists-with-different-credential":
          case "auth/auth-domain-config-required":
          case "auth/cancelled-popup-request":
          case "auth/operation-not-allowed":
          case "auth/operation-not-supported-in-this-environment":
          case "auth/popup-blocked":
          case "auth/popup-closed-by-user":
          case "auth/unauthorized-domain":
            openSnackbar(message);
            return;

          default:
            //this.props.openSnackbar(message);
            console.error(message);
            return;
        }
      })
      .finally(() => {
        //  setPerformingAction(false);
      });
  };

  return (
    <Box className={classes.root}>
      <img
        src={caixaPretaLogo}
        alt="Logo Caixa Preta"
        className={classes.logo}
      />
      <Grid className={classes.loginGrid}>
        <TextField
          required
          fullWidth
          className={classes.inputFields}
          size="small"
          id="txtEmail"
          label="E-mail"
          placeholder="Digite seu e-mail aqui"
          variant="outlined"
          margin="normal"
          InputLabelProps={{
            shrink: true
          }}
          onChange={(e, v) => {
            const { value } = e.target;
            setEmail(value.trim());
            enableFields();
          }}
        />
        <TextField
          required
          fullWidth
          className={classes.inputFields}
          id="txtPassword"
          size="small"
          label="Senha"
          placeholder="Digite sua senha aqui"
          variant="outlined"
          margin="normal"
          type="password"
          InputLabelProps={{
            shrink: true
          }}
          onChange={(e, v) => {
            const { value } = e.target;
            setPassword(value.trim());
            enableFields();
          }}
        />
        <Button
          variant="contained"
          className={classes.btnLogin}
          onClick={signIn}
        >
          Entrar
        </Button>
        <p>Ou entre com sua conta do Google</p>
        <Grid container>
          <Grid item xs={12}>
            <img
              className={classes.ssoIcons}
              alt="Logo do Google para login"
              src={googleIcon}
              onClick={() => {
                signInWithAuthProvider("google.com");
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Button className={classes.btnSignUp} onClick={onSignUpClick}>
            Quero me cadastrar
          </Button>
        </Grid>
        <Grid item xs={12}>
          <p className={classes.footer}>
            <strong>{totalQuestions || "..."}</strong> questões para
            CFS/CHO/EAP.
            <br />
            Cadastro gratuito!
          </p>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  LinearProgress
} from '@material-ui/core';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';

import httpClient from '../../services/httpClient';
import { Typography } from '@material-ui/core';
import VideoIcon from '../../assets/icon-video.svg';
import Header from '../Header/Header';
import Fuse from 'fuse.js';
import { useHistory } from 'react-router-dom';
import pan from '../../assets/pan.svg';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 660,
    margin: '0 auto'
  },
  list: {
    backgroundColor: '#ffffff',
    color: '#333333',
    borderRadius: 20,
    marginLeft: 8,
    marginRight: 8
  },
  title: {
    marginBottom: 20,
    marginLeft: 12,
    fontSize: 14,
    fontWeight: 'bold'
  },
  listItem: {
    background: '#CCCCCC'
  },
  name: {
    paddingLeft: 15,
    fontSize: 10
  },
  icon: {
    color: 'red'
  },
  tarja: {
    backgroundColor: 'yellow',
    color: '#333333'
  },
  pan: {
    width: 28,
    height: 28,
    padding: 0,
    margin: 0
  }
}));

const VideoGallery = props => {
  const { panelinha, user } = props;
  const history = useHistory();
  const classes = useStyles();
  const { videoTitle } = useParams();

  const [filter, setFilter] = useState('');
  const [videos, setVideos] = useState([]);
  const [allVideos, setAllVideos] = useState([]);
  const [loading, setLoading] = useState(false);

  if (!loading) {
    setLoading(true);
    httpClient.getVideos(user.uid).then(response => {
      if (videoTitle) {
        const video = response.data.find(
          v => v.title === decodeURIComponent(videoTitle)
          );
          if (video) {
            loadVideo(video);
          }
        }
        setAllVideos(response.data);
        setVideos(response.data);
      });
    }

  const loadVideo = video => {
    props.loadVideo(video);
    history.push({
      pathname: '/videos/play'
    });
  };

  const VideoLinks = () => {
    if (videos && videos.length) {
      return videos.map((video, index) => {
        return (
          <>
            <ListItem
              key={`videoItem_${index}`}
              className={!panelinha && video.panelinha && classes.tarja}
              onClick={() => {
                if (!video.panelinha || panelinha) {
                  loadVideo(video);
                } else {
                  // window.open('https://panelinha2022.nery.net.br');
                }
              }}
              button
            >
              <ListItemIcon
                alignItems="center"
                key={`videoItemPanIcon_${index}`}
              >
                {video.panelinha && (
                  <img className={classes.pan} src={pan} alt="Panelinha" />
                )}
              </ListItemIcon>
              <ListItemText
                className={classes.name}
                key={`videoListItem_${index}`}
                primary={video.title}
              />
              <ListItemIcon alignItems="center" key={`videoItemIcon_${index}`}>
                <PlayArrowRoundedIcon
                  className={classes.icon}
                  key={`icon_${index}`}
                />
              </ListItemIcon>
            </ListItem>
            <Divider className={classes.listItem} color="#333333" />
          </>
        );
      });
    }
    return <></>;
  };

  const Title = () => {
    if (!videos.length) {
      return (
        <>
          <Typography variant="h5">Carregando vídeos disponíveis</Typography>
          <LinearProgress variant="query" />
        </>
      );
    }
    return (
      <Typography className={classes.title}>
        Escolha uma aula na lista
      </Typography>
    );
  };

  const filterVideos = e => {
    const { value } = e.target;

    setFilter(value);

    if (value) {
      const options = {
        includeScore: true,
        ignoreLocation: true,
        threshold: 0.3,
        keys: ['title']
      };

      const fuse = new Fuse(allVideos, options);

      const result = fuse.search(value);

      setVideos(result.map(i => i.item));
      return;
    }
    setVideos(allVideos);
  };

  return (
    <div className={classes.root}>
      <Header
        key="videoHeader"
        icon={VideoIcon}
        placeholder="Digite para buscar uma aula"
        text={filter}
        onChange={filterVideos}
      />
      <Title />
      <List className={classes.list} component="nav" key="videoListComponent">
        <VideoLinks key="videoLinks" />
      </List>
      <Divider />
    </div>
  );
};

export default VideoGallery;

import React, { useState } from "react";
import { Box, Button, Grid } from "@material-ui/core";

import caixaPretaLogo from "../../assets/lg-cp-pan.png";
import httpClient from "../../services/httpClient";

import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 470,
    margin: "15px auto",
    textAlign: "center",
    color: "#333333",
    "& .Mui-disabled": {
      backgroundColor: "#BDBDBD",
      color: "#FAFAFA"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 20,
        borderColor: "#BDBDBD",
        color: "#333333"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 20,
        borderColor: "#BDBDBD",
        color: "#333333"
      },
      "& ::placeholder": {
        color: "#BDBDBD"
      }
    },
    "& .MuiInputLabel-outlined": {
      color: "#333333"
    },
    "& .MuiInputBase-input": {
      color: "#333333"
    }
  },
  logo: {
    width: "55%",
    margin: "0 auto",
    zIndex: 1,
    position: "relative",
    top: 40
  },
  loginGrid: {
    backgroundColor: "#FAFAFA",
    borderRadius: 20,
    padding: 20,
    paddingTop: 40,
    zIndex: 0,
    marginBottom: 20
  },
  inputFields: {
    color: "#333333"
  },
  btnLogin: {
    borderRadius: 20,
    backgroundColor: "#EB5757",
    color: "#FAFAFA",
    padding: "8px 16px",
    margin: 16
  },
  btnSignUp: {
    backgroundColor: "#FAFAFA",
    color: "#D73D32",
    borderRadius: 20,
    padding: "8px 16px"
  },
  footer: {
    color: "#FAFAFA",
    fontSize: "11px",
    textAlign: "center",
    marginTop: 40
  },
  ssoIcons: {
    backgroundColor: "#D73D32",
    borderRadius: 100,
    width: 48,
    padding: 10,
    cursor: "pointer"
  },
  green: {
    color: "green"
  }
}));

const ThanksPage = props => {
  const { user } = props;
  const [confirmed, setConfirmed] = useState(false);
  const onClick = () => {
    httpClient.sendEmail(user).then(result => setConfirmed(true));
  };
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <img
        src={caixaPretaLogo}
        alt="Logo Caixa Preta"
        className={classes.logo}
      />
      <Grid className={classes.loginGrid}>
        <Grid container>
          <Grid item xs={12}>
            <p>
              Estamos muito felizes que você aceitou fazer parte da Panelinha!
            </p>
            <p>
              Nossas aulas exclusivas serão no último sábado de cada mês. Elas
              ficam gravadas para assistir depois.
            </p>
            <p>Irei avisar os detalhes por e-mail antes de cada aula.</p>
            <p>
              Você deverá receber um e-mail de boas vindas onde irei te contar
              sobre um brinde exclusivo ainda não anunciado!
            </p>
            <p>
              Nossos contatos podem ser feitos via e-mail(panelinha@nery.net.br)
              ou WhatsApp (31) 995526078
            </p>
            <p>
              Como bônus eu gostaria de convidá-lo para assistir uma aula sobre
              a <b>ITC 15 - Redes Sociais</b> às 20h do dia 15/07.
            </p>
            <p>Até lá!</p>
            {user && !confirmed && (
              <Button variant="contained" color="secondary" onClick={onClick}>
                Clique aqui para finalizar sua inscrição
              </Button>
            )}
            {user && confirmed && (
              <>
                <p className={classes.green}>
                  Inscrição completa! Verifique sua caixa de e-mail!
                </p>
                <p className={classes.green}>
                  Caso não receba em até 10 minutos,
                  <br /> entre em contato pelos canais acima!
                </p>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <p className={classes.footer}>
            Sistema exclusivo para alunos do Prof. Rubens Nery
          </p>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ThanksPage;

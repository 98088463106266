import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Box,
  Button,
  Chip,
  Grid,
  TextField,
  Tabs,
  Tab,
  Typography,
  Snackbar,
  CircularProgress
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import MessageIcon from '@material-ui/icons/Message';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import CheckIcon from '@material-ui/icons/Check';
import httpClient from '../../services/httpClient';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 660,
    margin: '0 auto',
    marginTop: 30,
    backgroundColor: 'inherit',
    flexGrow: 1
  },
  listItem: {
    backgroundColor: '#CCCCCC',
    color: '#333333',
    borderRadius: 8,
    marginTop: 8,
    marginLeft: 8,
    marginRight: 8,
    minHeight: 60,
    padding: 0,
    cursor: 'pointer'
  },
    empty: {
    backgroundColor: '#CCCCCC',
    color: '#333333',
    borderRadius: 8,
    marginTop: 8,
    marginLeft: 8,
    marginRight: 8,
    minHeight: 60,
    padding: 20,
    cursor: 'pointer'
  },
  listContent: {
    backgroundColor: '#FEFEFE',
    padding: 8
  },
  redBar: {
    backgroundColor: '#EB5757',
    width: 48,
    height: 8,
    marginBottom: 15
  },
  icon: {
    fontSize: 16
  },
  subtitle: {
    backgroundColor: '#CCCCCC',
    fontWeight: 'bold',
    borderRadius: 8,
    padding: 12
  },
  inputFields: {
    minWidth: 700,
    marginBottom: 30
  }
}));

const NOTIFICATION_TYPES = {
  COMMENT: 'comment',
  BIZU: 'bizu',
  GENERAL: 'general'
};

const Comments = props => {
  const { user, setNotificationsCount, amplitude } = props;
  const classes = useStyles();
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  };

  useEffect(() => {
    amplitude.logEvent('Notifications Click');
    httpClient.getNotificationsFromUser(user.email, true)
      .then(result => {
        setNotifications(result);
    });
  }, [user]);

  const markAsRead = notificationId => {
    notifications.find(x => x._id === notificationId).read = true;
    httpClient.patchNotificationRead(notificationId).then(() => setNotificationsCount());
  };

  const Header = () => (
    <Grid item xs={12}>
      <Typography variant="h5">Notificações</Typography>
      <div className={classes.redBar}>&nbsp;</div>
    </Grid>
  );

  const getTag = ({tags}) => {
    if (tags) {
      const tagsToExclude = new Set([
        '',
        'EXERCICÍOS DE FIXAÇÃO',
        'EXERCÍCIOS',
        'EXERCÍCIOS',
        'EXERCÍCIOS DE FIXAÇÃO',
        'EXERCÍCIOS ',
        /^\$/,
        'EAP',
        'CFS',
        'CHO',
        'INÉDITAS',
        'INEDITAS',
        'PROVAS',
        'CONHECIMENTOS PROFISSIONAIS',
        'Conhecimentos Profissionais'
      ]);

      if (Array.isArray(tags) && tags.length > 0) {
        const result = new Set(tags.filter((x) => !tagsToExclude.has(x)));
        if (result.size > 0){
          return Array.from(result).join(', ');
        }
      }
    }
  }

  const notificationClick = (notification) => {
    markAsRead(notification._id);
    if (notification.type === NOTIFICATION_TYPES.COMMENT) {
      history.push({
        pathname: `/question/${notification.questionId}`
      });
    }
    if (notification.type === NOTIFICATION_TYPES.BIZU) {
      history.push({
        pathname: `/bizu/${notification.content}`
      });
    }
    if (notification.type === NOTIFICATION_TYPES.GENERAL && notification.url) {
      window.open(notification.url);
    }
  }

  const notificationText = (notification) => 
    notification.type === NOTIFICATION_TYPES.GENERAL 
    ? notification.content 
    : notification.content && `${notification.content.slice(0, 200)} ${
                    notification.content.length > 200 ? '...' : ''
                  }`;

  const NotificationHeader = ({type}) => {
    if (type === NOTIFICATION_TYPES.COMMENT) {
      return (<>
      <Grid item xs={1} className={classes.subtitle}>
        <MessageIcon className={classes.icon} />
      </Grid>
      <Grid item xs={11} className={classes.subtitle}>
        Comentário em questão
      </Grid></>);
    }

    if (type === NOTIFICATION_TYPES.BIZU) {
      return (<>
      <Grid item xs={1} className={classes.subtitle}>
        <AttachFileIcon className={classes.icon} />
      </Grid>
      <Grid item xs={11} className={classes.subtitle}>
        Novo material disponível
      </Grid></>);
    }

    if (type === NOTIFICATION_TYPES.GENERAL) {
      return (<>
      <Grid item xs={1} className={classes.subtitle}>
        <ErrorIcon className={classes.icon} />
      </Grid>
      <Grid item xs={11} className={classes.subtitle}>
        Aviso geral
      </Grid></>);
    }
    return (<>
      <Grid item xs={1} className={classes.subtitle}>
        <MessageIcon className={classes.icon} />
      </Grid>
      <Grid item xs={11} className={classes.subtitle}>
      </Grid></>);
    
  };
  
  const NotificationItems = ({ list }) => {
    if (list.length) {
      return list.map(notification => {
        const tag = getTag(notification);
        return (
            <Grid onClick={() => notificationClick(notification)}  key={Math.random()} container className={`${classes.listItem} ${classes.read}`}>
              <NotificationHeader type={notification.type} />
              <Grid item xs={10} className={classes.listContent}>
                {notificationText(notification)}
              </Grid>
              <Grid item xs={2} className={classes.listContent}>
                {notification.type === 'general' ? <CheckIcon /> : <ArrowForwardIcon />}
              </Grid>
              {tag && <Grid item xs={12} className={classes.listContent}>
                <Chip
                  size="small"
                  icon={<LocalOfferIcon color="secondary" />}
                  label={tag}
                />
              </Grid>}
            </Grid>
          );
      });
    }
    if (user) {
      return (
        <Grid item xs={10} className={classes.empty}>
          Nenhuma notificação por enquanto!
          <br />
          Utilize a caixa de comentários disponível no Modo Treinamento para
          interagir nas questões!
          <br />
          <br />
          As questões que você comentar aparecerão aqui para acompanhar a
          resposta que receber!
        </Grid>
      );
    }
    //window.location.href = 'https://panelinha2022.nery.net.br';
    return <></>;
  };

  const Toast = () => {
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      setOpen(false);
    };
    return (
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Notificação distribuída com sucesso!
        </Alert>
      </Snackbar>
    );
  }

  const CreateNotification = () => {
    const [text, setText] = useState('');
    const [url, setUrl] = useState('');
    const [errorUrl, setErrorUrl] = useState(false);
    const [audience, setAudience] = useState('panelinha');
    const [loading, setLoading] = useState(false);
    
    const isValidUrl = (string) => {
      if (string === '') {
        return true;
      }
      let url;
      
      try {
        url = new URL(string);
      } catch (_) {
        return false;  
      }

      return url.protocol === 'http:' || url.protocol === 'https:';
    }

    const handleTextChange = (event) => {
      setText(event.target.value);
    };
    
    const handleUrlChange = (event) => {
      setUrl(event.target.value);
      const isValid = !(isValidUrl(event.target.value));
      setErrorUrl(isValid);
      
    };

    const handleAudienceChange = (event) => {
      setAudience(event.target.value);
    };

    const saveNotification = () => {
      if (text.length > 10) {
        setLoading(true);
        httpClient
          .postNotification(user.uid, audience, text, url, false)
          .then(() => {
            setOpen(true);
            setLoading(false);
            setText('');
          })
      }
    }

    return (
      <>
      <Toast />
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <TextField
            id="audience"
            disabled={loading}
            select
            label="Quem deverá visualizar?"
            value={audience}
            onChange={handleAudienceChange}
            fullWidth={true}
            className={classes.inputFields}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          >
            {[
              {value: 'all', label: 'Todos'},
              {value: 'panelinha', label: 'Panelinha'},
              {value: 'free', label: 'Gratuitos'}
            ].map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="texto-notificacao"
            disabled={loading}
            label="Conteúdo da notificação"
            placeholder="Conteúdo da notificação"
            error={text.length < 20}
            key="notification-text"
            defaultValue={text}
            onChange={handleTextChange}
            minRows={4}
            fullWidth={true}
            multiline
            variant="outlined"
            className={classes.inputFields}
          /></Grid>
          <Grid item xs={12}>
          <TextField
            error={errorUrl}
            id="url-notificacao"
            disabled={loading}
            label="URL da notificação"
            placeholder="URL da notificação"
            type={'url'}
            key="notification-url"
            defaultValue={url}
            onChange={handleUrlChange}
            minRows={1}
            fullWidth={true}
            multiline
            variant="outlined"
            className={classes.inputFields}
          /></Grid>
          <Grid item xs={12}>
            <Button 
              disabled={loading || text.length < 20}
              fullWidth
              variant="contained"
              size="large"
              color="secondary" onClick={saveNotification}>
                {loading ? <CircularProgress /> : 'Criar'}
              </Button>
          </Grid>
      </Grid>
      </>
    );
  }

  const NotificationTabs = () => {
    return (
      <>
        <AppBar position="static">
          <Tabs value={value} variant="fullWidth" onChange={handleChange}>
            <Tab label="Não lidas" />
            <Tab label="Lidas" />
            { user.admin && <Tab label="Criar" /> }
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Grid container className={classes.root}>
            <NotificationItems key="userList" list={notifications.filter(n => !n.read)} />
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container className={classes.root}>
            <NotificationItems key="userList" list={notifications.filter(n => n.read)} />
          </Grid>
        </TabPanel>
        { user.admin && <TabPanel value={value} index={2}>
          <Grid container className={classes.root}>
            <CreateNotification />
          </Grid>
        </TabPanel>}
      </>
    );
  };

  return (
    <Grid container className={classes.root}>
      <Header />
      <NotificationTabs />
    </Grid>
  );
};

export default Comments;

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  LinearProgress
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ErrorIcon from '@material-ui/icons/Error';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Header from '../../components/Header/Header';
import httpClient from '../../services/httpClient';
import { Typography } from '@material-ui/core';
import TrainingIcon from '../../assets/icon-treinamento.svg';
import Fuse from 'fuse.js';
import { useParams } from 'react-router';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 660,
    margin: '0 auto'
  },
  list: {
    backgroundColor: '#ffffff',
    color: '#333333',
    borderRadius: 20,
    marginLeft: 8,
    marginRight: 8
  },
  title: {
    margin: '20px 15px',
    fontSize: 14,
    fontWeight: 'bold'
  },
  listItem: {
    background: '#CCCCCC'
  },
  name: {
    paddingLeft: 15,
    fontSize: 10
  },
  icon: {
    color: 'gray'
  },
  infoBar: {
    marginTop: 15
  },
  redBox: {
    backgroundColor: '#EB5757',
    width: '50%',
    marginBottom: 50,
    borderRadius: 20,
    padding: 10
  },
  yellowBox: {
    backgroundColor: '#c98000',
    width: '50%',
    marginBottom: 50,
    borderRadius: 20,
    padding: 10
  },
  rightBorder: {
    borderRight: '2px solid #333333'
  },
  realtimeData: {
    textAlign: 'center',
    color: '#FAFAFA',
    heigth: '100%'
  },
  center: {
    textAlign: 'center'
  },
  number: {
    fontWeight: 'bold',
    fontSize: '42px',
    margin: 0,
    padding: 0
  },
  infoBarIcon: {
    fontSize: 36
  },
  margin30: {
    marginTop: 30
  },
  new: {
    backgroundColor: '#fffc9e',
    borderRadius: 15,
    fontWeight: 'bold'
  }
}));

const BizuList = props => {
  const { user, openSettings } = props;
  const classes = useStyles();
  const { bizuId } = useParams();

  const [filter, setFilter] = useState('');
  const [bizus, setBizus] = useState([]);
  const [allBizus, setAllBizus] = useState([]);
  const [isUserAllowed, setUserAllowed] = useState(null);
  let newWindow;

  const filterTags = e => {
    const { value } = e.target;
    setFilter(value);

    if (value) {
      const options = {
        includeScore: true,
        threshold: 0.5,
        keys: ['title']
      };

      const fuse = new Fuse(allBizus, options);

      const result = fuse.search(value);

      setBizus(result.map(i => i.item));
      return;
    }
    setBizus(allBizus);
  };

  const fetchData = () => {
    return httpClient.getBizus(user.uid).then(response => {
      const { bizus, userAllowed } = response.data;
      setAllBizus(bizus);
      setBizus(bizus);
      setUserAllowed(userAllowed);
    });
  };

  useEffect(() => {
    httpClient.getBizus(user.uid).then(response => {
      const { bizus, userAllowed } = response.data;
      setAllBizus(bizus);
      setBizus(bizus);
      setUserAllowed(userAllowed);
    });
  }, [user]);

  useEffect(() => {
    if (bizuId) {
      filterTags({ target: { value: bizuId } });
    }
  }, [bizuId]);

  const downloadBizu = bizuId => {
    if (isUserAllowed) {
      httpClient.getBizuFile(bizuId, user.uid).then(response => {
        newWindow.location.href = response.data.fileUrl;
      });
    } else {
      openSettings();
    }
  };

  const AlertUpdateProfile = () => (
    <>
      <Grid container className={classes.infoBar}>
        <Grid item xs={12} className={classes.redBox}>
          <Grid container>
            <Grid item xs={2}>
              <ErrorIcon className={classes.infoBarIcon} />
            </Grid>
            <Grid item xs={10}>
              Para ter acesso aos documentos, você precisa finalizar seu
              cadastro, completando as informações pendentes.
              <p>Clique no botão abaixo para atualizar.</p>
            </Grid>
            <Grid item xs={12} className={classes.center}>
              <Button
                variant="contained"
                color="primary"
                onClick={openSettings}
              >
                Atualizar cadastro
              </Button>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={10} className={classes.margin30}>
              Após atualizar seus dados, clique abaixo para carregar os Bizus.
            </Grid>
            <Grid item xs={12} className={classes.center}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => fetchData()}
              >
                Pronto!
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  const AlertFilePassword = () => (
    <>
      <Grid container className={classes.infoBar}>
        <Grid item xs={12} className={classes.yellowBox}>
          <Grid container>
            <Grid item xs={2}>
              <VpnKeyIcon className={classes.infoBarIcon} />
            </Grid>
            <Grid item xs={10}>
              <b>ATENÇÃO:</b> A senha dos arquivos são os{' '}
              <b>
                <u>5 PRIMEIROS DÍGITOS DO SEU CPF!</u>
              </b>
              <br />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  const BizuItems = () => {
    if (bizus && bizus.length) {
      return bizus.map(bizu => {
        return (
          <>
            <ListItem button className={bizu.new ? classes.new : ''}>
              <ListItemText
                className={classes.name}
                primary={bizu.title}
                onClick={() => {
                  newWindow = window.open('', 'bizu');
                  downloadBizu(bizu.id);
                }}
              />
              <ListItemIcon>
                <AttachFileIcon className={classes.icon} />
              </ListItemIcon>
            </ListItem>
            <Divider className={classes.listItem} color="#333333" />
          </>
        );
      });
    }
    return <></>;
  };

  const Title = () => {
    if (!bizus.length) {
      return (
        <>
          <Typography className={classes.title}>
            Carregando materiais disponíveis
          </Typography>
          <LinearProgress variant="query" />
        </>
      );
    }
    return (
      <Typography className={classes.title}>
        Escolha um material para baixar
      </Typography>
    );
  };

  const InformationBar = () => {
    if (isUserAllowed === false) {
      return <AlertUpdateProfile />;
    } else if (isUserAllowed === true) {
      return <AlertFilePassword />;
    }
    return '';
  };

  const BizuContent = () => {
    if (isUserAllowed) {
      return (
        <>
          <Header
            icon={TrainingIcon}
            placeholder="Digite para procurar um bizu"
            text={filter}
            onChange={filterTags}
          />
          <Title />
          <List
            className={classes.list}
            component="nav"
            key="tagsListComponent"
          >
            <BizuItems />
          </List>
        </>
      );
    }
    return '';
  };

  return (
    <div className={classes.root}>
      <Typography variant="h5">Bizu</Typography>
      <InformationBar />
      <BizuContent />
    </div>
  );
};

export default BizuList;

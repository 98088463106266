import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { Button, Grid } from "@material-ui/core";

import httpClient from "../../services/httpClient";
import CommentBox from "../CommentBox";
import AttachmentBox from "../AttachmentBox";

const useStyles = makeStyles({
  group: {
    width: "100%"
  },
  item: {
    background: "white",
    textAlign: "left",
    justifyContent: "flex-start",
    color: "#000000",
    fontWeight: 400,
    margin: 10,
    border: "1px solid #ccc",
    borderRadius: 10,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#CCC"
    },
    "&:not(:first-child)": {
      borderRadius: 10
    },
    "&:first-child": {
      borderRadius: 10
    }
  },
  confirmButton: {
    borderRadius: 10
  },
  center: {
    textAlign: "center"
  },
  wrong: {
    background: "#ff8a8a",
    "&:hover": {
      backgroundColor: "#ff8a8a"
    }
  },
  right: {
    background: "#aef5c8",
    "&:hover": {
      backgroundColor: "#aef5c8" //"#5ac782"
    }
  },
  selected: {
    border: "3px solid #333",
    fontWeight: "bold"
  }
});

const ButtonPanel = props => {
  const { question, questionId } = props;
  const classes = useStyles();
  const [selected, setSelected] = useState("");
  const [confirmed, setConfirmed] = useState(false);
  const [result, setResult] = useState("");

  const clearSelection = () => {
    setSelected("");
    setConfirmed(false);
  };

  useEffect(() => {
    if (questionId && question && question.correta) {
      setSelected(question.correta.toLowerCase());
    }
  }, [question, questionId]);

  const registerAnswer = async (question, user, choice) => {
    const answer = {
      questionId: question._id,
      questionCorrect: question.correta.toLowerCase(),
      fileName: question.fileName,
      choice,
      mode: question.mode,
      status:
        question.correta.toLowerCase() === choice.toLowerCase()
          ? "sucess"
          : "error",
      userId: user.uid,
      userMail: user.email,
      elapsedTime: question.elapsedTime
    };

    await httpClient.postAnswer(answer);
  };

  const parseAnswer = async (question, currentAnswer, user, showResult) => {
    const errorProps = {
      imgType: "error",
      title: "ERRRROU!!!",
      correta: question.correta,
      text: "Não foi dessa vez, mas não desiste não! Bora pra próxima!"
    };

    const successProps = {
      imgType: "success",
      title: "AÍ SIM HEIN!",
      correta: question.correta,
      text:
        "Gostei de ver! Se continuar desse jeito vai chegar no topo do ranking!"
    };

    const result =
      question.correta.toLowerCase() === currentAnswer.toLowerCase()
        ? successProps
        : errorProps;

    showResult(result);
    await registerAnswer(question, user, currentAnswer);
    clearSelection();
  };

  const handleSelection = (event, selected) => {
    setSelected(selected);
  };

  const confirmSelection = ({ question, user, showResult }) => {
    if (selected) {
      if (question.mode !== "arcade") {
        setConfirmed(true);
        setResult(
          question.correta.toLowerCase() === selected.toLowerCase()
            ? "success"
            : "error"
        );
        registerAnswer(question, user, selected);
      } else {
        parseAnswer(question, selected, user, showResult);
      }
    }
  };

  const nextQuestion = () => {
    clearSelection();
    props.handleClose("next", result);
  };

  if ((!confirmed && !questionId) || question.mode === "arcade") {
    return (
      <Grid container alignContent="center" alignItems="center">
        <Grid item xs={12}>
          <ToggleButtonGroup
            className={classes.group}
            value={selected}
            exclusive
            onChange={handleSelection}
            orientation="vertical"
            aria-label="Alternativas"
          >
            <ToggleButton
              value="A"
              aria-label="Alternativa A"
              className={classes.item}
            >
              {question.altA}
            </ToggleButton>
            <ToggleButton
              value="b"
              aria-label="Alternativa B"
              className={classes.item}
            >
              {question.altB}
            </ToggleButton>
            <ToggleButton
              value="c"
              aria-label="Alternativa C"
              className={classes.item}
            >
              {question.altC}
            </ToggleButton>
            <ToggleButton
              value="d"
              aria-label="Alternativa D"
              className={classes.item}
            >
              {question.altD}
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12} className={classes.center}>
          <Button
            className={classes.confirmButton}
            variant="contained"
            color="secondary"
            onClick={() => confirmSelection(props)}
          >
            Responder!
          </Button>
        </Grid>
      </Grid>
    );
  }

  const getClassName = option => {
    if (option && selected) {
      return `${classes.item} ${
        question.correta.toLowerCase() === option.toLowerCase()
          ? classes.right
          : selected.toLowerCase() === option.toLowerCase() &&
            question.correta.toLowerCase() !== selected.toLowerCase()
          ? classes.wrong
          : ""
      }`;
    }
    return classes.item;
  };

  return (
    <Grid container alignContent="center" alignItems="center">
      <Grid item xs={12}>
        <ToggleButtonGroup
          className={classes.group}
          exclusive
          orientation="vertical"
          aria-label="Alternativas"
        >
          <ToggleButton
            value="A"
            aria-label="Alternativa A"
            className={getClassName("a")}
          >
            {question.altA}
          </ToggleButton>
          <ToggleButton
            value="b"
            aria-label="Alternativa B"
            className={getClassName("b")}
          >
            {question.altB}
          </ToggleButton>
          <ToggleButton
            value="c"
            aria-label="Alternativa C"
            className={getClassName("c")}
          >
            {question.altC}
          </ToggleButton>
          <ToggleButton
            value="d"
            aria-label="Alternativa D"
            className={getClassName("d")}
          >
            {question.altD}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      {!questionId && (
        <Grid item xs={12} className={classes.center}>
          <Button
            className={classes.confirmButton}
            variant="contained"
            color="secondary"
            onClick={() => nextQuestion(props)}
          >
            Próxima
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <CommentBox
          user={props.user}
          questionId={props.question._id}
          panelinha={props.panelinha}
        />
      </Grid>
      <Grid item xs={12}>
        <AttachmentBox
          user={props.user}
          questionId={props.question._id}
          panelinha={props.panelinha}
        />
      </Grid>
    </Grid>
  );
};

export default ButtonPanel;

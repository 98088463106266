import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  Checkbox,
  Box,
  FormControlLabel,
  Divider,
  AppBar,
  Toolbar
} from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import { Grid } from '@material-ui/core';

import authentication from '../../services/authentication';

import caixaPretaLogo from '../../assets/lg-cp.png';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  logo: {
    height: 100
  },
  box: {
    background: 'rgba(48, 48, 48, .3)',
    padding: 20
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StartupDialog = ({ analytics, open, handleClose }) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  const handleChange = event => {
    setChecked(event.target.checked);
  };

  const saveChangesAndClose = async event => {
    if (checked) {
      analytics.logEvent('accept_terms');

      authentication.acceptTerms(1.0).then(() => {
        handleClose(event);
      });
    }
  };

  const TopBar = () => (
    <AppBar className={classes.appBar}>
      <Toolbar>
        {/* <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
          <CloseIcon />
        </IconButton> */}
        <Typography variant="h6" className={classes.title}>
          Atualização dos termos de uso
        </Typography>
        <Button
          color="inherit"
          onClick={saveChangesAndClose}
          disabled={!checked}
        >
          Salvar
        </Button>
      </Toolbar>
    </AppBar>
  );

  const DialogContent = () => (
    <div
      style={{
        flexGrow: 1,
        maxWidth: 600,
        margin: '15px auto'
      }}
    >
      <Grid container className={classes.box}>
        <Grid item xs={2}>
          <img
            src={caixaPretaLogo}
            alt="Logo Caixa Preta"
            className={classes.logo}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={7}>
          <Typography variant="h4" align="center">
            Termos de uso
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.box}>
        <Grid item xs={12}>
          <Typography variant="h6">
            Ao comprar o acesso à Panelinha do CAIXA PRETA, você declara estar
            ciente dos termos abaixo.
          </Typography>
          <Typography variant="h6">GARANTIA</Typography>
          <p>
            O prazo de garantia de satisfação para compras na internet é de 7
            dias corridos. Durante este período você tem o direito
            inquestionável ao cancelamento da sua inscrição, dando direito à
            devolução do seu dinheiro investido. Após este prazo, não serão
            aceitos pedidos de devolução, uma vez que o usufruto da aplicação
            denominada CAIXA PRETA já terá tido início.
          </p>
          <Typography variant="h6">SUA CONTA</Typography>
          <p>
            O seu usuário é pessoal e intransferível, não sendo permitido o
            compartilhamento de contas com terceiros.
            <br />
            Qualquer ação suspeita de compartilhamento de contas causará banição
            do usuário dentro da plataforma por tempo indeterminado sem direito
            a devolução do dinheiro após o período de garantia de 7 dias.
          </p>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                name="checked"
              />
            }
            label="Declaro estar ciente e de acordo com os termos acima."
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              color="secondary"
              variant="outlined"
              onClick={saveChangesAndClose}
              disabled={!checked}
            >
              Salvar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={saveChangesAndClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        TransitionComponent={Transition}
      >
        <TopBar />
        <DialogContent />
      </Dialog>
    </div>
  );
};

export default StartupDialog;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Divider,
  ListItem,
  LinearProgress
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import httpClient from '../../services/httpClient';

const useStyles = makeStyles({
  box: {
    backgroundColor: '#fafafa',
    borderRadius: 10,
    padding: 20,
    marginTop: 40,
    marginBottom: 40,
    color: '#333333'
  },
  icon: {
    fontSize: 16
  },
  mainIcon: {
    fontSize: 24
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginLeft: 10
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: 15
  },
  subtopic: {
    marginLeft: 30,
    marginTop: 10
  },
  list: {
    marginLeft: 50
  }
});

const AttachmentBox = ({ panelinha, questionId }) => {
  const classes = useStyles();
  const history = useHistory();
  const [videos, setVideos] = useState([]);
  const [bizus, setBizus] = useState([]);
  const [loading, setLoading] = useState(true);

  const VideoItems = ({ list }) =>
    list.map((item, index) => (
      <ListItem
        key={index}
        onClick={() =>
          history.push({
            pathname: `/videos/play/${encodeURIComponent(item.title)}`
          })
        }
      >
        - {item.title} &nbsp;
        <OpenInNewIcon className={classes.icon} />
      </ListItem>
    ));

  const BizuItems = ({ list }) =>
    list.map((item, index) => (
      <ListItem
        key={index}
        onClick={() =>
          history.push({
            pathname: `/bizu/${encodeURIComponent(item.title)}`
          })
        }
      >
        - {item.title} &nbsp;
        <OpenInNewIcon className={classes.icon} />
      </ListItem>
    ));

  useEffect(() => {
    if (panelinha && questionId) {
      httpClient.getAttachments(questionId).then(result => {
        setVideos(result.data.videos);
        setBizus(result.data.bizus);
        setLoading(false);
      });
    }
  }, [panelinha, questionId]);

  return (
    <Grid
      container
      alignContent="center"
      alignItems="center"
      className={classes.box}
    >
      <Grid item xs={12}>
        <AttachFileIcon className={classes.mainIcon} />
        <span className={classes.title}>Conteúdos relacionados</span>
        {loading && <LinearProgress color="secondary" />}
      </Grid>
      {bizus.length && (
        <Grid item xs={12} md={6} lg={6} className={classes.subtopic}>
          <PictureAsPdfIcon className={classes.icon} />
          <span className={classes.subtitle}>Bizu</span>
          <Divider />
          <BizuItems list={bizus} />
        </Grid>
      )}
      {videos.length && (
        <Grid item xs={12} md={6} lg={6} className={classes.subtopic}>
          <OndemandVideoIcon className={classes.icon} />
          <span className={classes.subtitle}>Video aulas</span>
          <VideoItems list={videos} />
          <Divider />
        </Grid>
      )}
    </Grid>
  );
};

export default AttachmentBox;

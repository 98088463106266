import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Fab,
  Grid,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  LinearProgress,
  Tooltip,
  Typography
} from '@material-ui/core';
import RepeatIcon from '@material-ui/icons/Repeat';
import CheckIcon from '@material-ui/icons/Check';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import EyeIcon from '@material-ui/icons/Visibility';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import Header from '../Header/Header';
import httpClient from '../../services/httpClient';
import TrainingIcon from '../../assets/icon-treinamento.svg';
import Fuse from 'fuse.js';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 660,
    margin: '0 auto'
  },
  list: {
    backgroundColor: '#ffffff',
    color: '#333333',
    borderRadius: 20,
    marginLeft: 8,
    marginRight: 8
  },
  listRecap: {
    backgroundColor: 'gold',
    color: '#333333',
    borderRadius: 20,
    margin: 8,
    '& .MuiListItemText-secondary': {
      color: '#777777'
    }
  },
  title: {
    margin: '20px 15px',
    fontSize: 14,
    fontWeight: 'bold'
  },
  listItem: {
    borderBottom: '1px solid #CCCCCC'
  },
  listItemSelected: {
    backgroundColor: 'yellow',
    borderBottom: '1px solid #CCCCCC'
  },
  name: {
    paddingLeft: 15,
    fontSize: 10
  },
  icon: {
    color: 'red'
  },
  info: {
    fontSize: 13
  },
  infoTitle: {
    fontSize: 20,
    textAlign: 'center',
    color: '#777777'
  },
  good: {
    color: 'green'
  },
  ok: {
    color: 'olive'
  },
  bad: {
    color: 'red'
  },
  new: {
    color: 'gold'
  },
  border: {
    textAlign: 'center'
  },
  gridSecondary: {
    color: '#777777'
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  fabStart: {
    position: 'fixed',
    bottom: theme.spacing(10),
    right: theme.spacing(2),
    backgroundColor: 'green',
    '&:hover': {
      backgroundColor: '#yellow'
    }
  },
  checkbox: {
    color: '#EB5757'
  }
}));

const percent = number => {
  const result = (number * 100).toFixed(2);
  return result < 100 ? result : (100).toFixed(2);
};

const performance = ({ error, success }) => {
  if (error === 0 && success === 0) {
    return (0).toFixed(2);
  }
  return percent(success / (error + success));
};

const TrainingTags = props => {
  const { user, amplitude } = props;
  const uid = user.uid;
  const history = useHistory();
  const classes = useStyles();

  const [filter, setFilter] = useState('');
  const [tags, setTags] = useState([]);
  const [allTags, setAllTags] = useState([]);

  const [multiple, setMultiple] = useState(false);
  const [ready, setReady] = useState(false);
  const [hasRecap, setRecap] = useState(false);
  const [checked, setChecked] = useState([]);

  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useEffect(() => {
    httpClient.getTags(uid).then(responseTags => {
      if (
        responseTags.data &&
        responseTags.data.tags &&
        responseTags.data.tags.length
      ) {
        setAllTags(responseTags.data.tags);
        setTags(responseTags.data.tags);
        setRecap(responseTags.data.hasRecap);
        setReady(true);
      }
    });
  }, [uid]);

  const goToTraining = () => {
    amplitude.logEvent('Treinamento múltiplo iniciado', {
      qtdTags: checked.length,
      tags: checked.join('___')
    });
    history.push({
      pathname: '/trainingMultiple/' + checked.join('___')
    });
  };

  const loadTag = tag => {
    if (tag === 'RECAP') {
      amplitude.logEvent('Repescagem iniciada');
    } else {
      amplitude.logEvent('Treinamento simples iniciado', {
        tag
      });
    }
    props.changeTag(tag);
    history.push({
      pathname: '/training/' + tag
    });
  };

  const getColorAnswers = percent => {
    if (percent > 0) {
      if (percent < 60) {
        return classes.bad;
      }
      if (percent < 95) {
        return classes.ok;
      }
      if (percent >= 95) {
        return classes.good;
      }
    }
  };

  const getColorErrors = percent => {
    if (percent < 60) {
      return classes.bad;
    }
    if (percent < 90) {
      return classes.ok;
    }
    return classes.good;
  };

  const handleClick = tag => {
    if (multiple) {
      return handleToggle(tag);
    }
    loadTag(tag);
  };

  const MemoListItem = item => {
    const { tag, questions } = item;
    const { total, answers } = questions;

    const errors = performance(item);
    const answeredQuestions = percent(answers / total);
    return (
      <ListItem
        button
        key={`listItem#${tag}`}
        className={classes.listItem}
        onClick={() => handleClick(tag)}
      >
        {multiple && (
          <ListItemIcon>
            {checked.indexOf(tag) !== -1 && (
              <CheckIcon className={classes.checkbox} />
            )}
          </ListItemIcon>
        )}
        <ListItemText
          className={classes.name}
          primary={
            <Grid container alignContent="center" alignItems="center">
              <Grid item xs={8} xl={8}>
                {tag}
              </Grid>
              <Grid item xs={2} xl={2} className={classes.info}>
                <Tooltip title="Percentual de questões já visualizadas e respondidas dentre todas as disponíveis. Quanto MAIOR, melhor!">
                  <Box
                    alignItems="center"
                    className={classes.border}
                    display={answeredQuestions <= 0 ? 'none' : 'default'}
                  >
                    <EyeIcon className={classes.infoTitle} />
                    <br />
                    <span className={getColorAnswers(answeredQuestions)}>
                      {answeredQuestions}%
                    </span>
                  </Box>
                </Tooltip>
              </Grid>
              <Grid item xs={2} xl={2} className={classes.info}>
                <Tooltip title="Percentual de acertos dentre todas as vezes em que respondeu uma questão deste tema. Quanto MAIOR, melhor!">
                  <Box
                    alignItems="center"
                    className={classes.border}
                    display={answeredQuestions <= 0 ? 'none' : 'default'}
                  >
                    <ThumbUpIcon className={classes.infoTitle} />
                    <br />
                    <span className={getColorErrors(errors)}>{errors}%</span>
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          }
          onClick={() => handleClick(tag)}
        />
      </ListItem>
    );
  };

  const RecapLink = () => (
    <List
      className={classes.listRecap}
      component="nav"
      key="tagsListRecapComponent"
    >
      <ListItem
        button
        key={'listItem#Recap'}
        onClick={() => handleClick('RECAP')}
      >
        <ListItemText
          className={classes.name}
          primary={
            <Grid container>
              <Grid item xs={1}>
                <RepeatIcon />
              </Grid>
              <Grid item xs={10}>
                Repescagem 2.0
              </Grid>
            </Grid>
          }
          secondary="Errou alguma questão? Ela está aqui para você revisar! AGORA ATUALIZADO EM TEMPO REAL!"
          onClick={() => handleClick('RECAP')}
        />
      </ListItem>
    </List>
  );

  const TagLinks = () => (
    <List className={classes.list} component="nav" key="tagsListComponent">
      {tags
        .filter(item => item.tag.trim() !== '')
        .map(item => MemoListItem(item))}
    </List>
  );

  const Title = () => {
    if (!tags.length) {
      return (
        <>
          <Typography className={classes.title}>
            Carregando treinamentos disponíveis
          </Typography>
          <LinearProgress variant="query" />
        </>
      );
    }
    return (
      <Typography className={classes.title}>
        Escolha um tema na lista{' '}
        <span className={classes.new}>
          ou clique no botão abaixo para habilitar a seleção de mais de um tema!
        </span>
      </Typography>
    );
  };

  const filterTags = e => {
    const { value } = e.target;
    setFilter(value);

    if (value) {
      const options = {
        includeScore: true,
        threshold: 0.5
      };

      const fuse = new Fuse(
        allTags.map(x => x.tag),
        options
      );

      const result = fuse.search(value);
      const foundTags = result.map(i => i.item);

      const filteredTags = allTags.filter(x => foundTags.includes(x.tag));

      setTags(filteredTags);
      return;
    }
    setTags(allTags);
  };

  const ActionButton = () => (
    <>
      <Fab
        variant="extended"
        className={classes.fab}
        color="secondary"
        onClick={() => {
          const newMultiple = !multiple;
          const eventName = newMultiple
            ? 'Treinamento - Click - Seleção múltipla'
            : 'Treinamento - Click - Seleção simples';
          amplitude.logEvent(eventName, {
            tags: checked.join('___')
          });
          setMultiple(!multiple);
        }}
      >
        {multiple && (
          <>
            <ArrowBackIcon />
            &nbsp; Voltar à seleção única
          </>
        )}
        {!multiple && (
          <>
            <GroupWorkIcon />
            &nbsp; Habilitar seleção de temas
          </>
        )}
      </Fab>
      {multiple && checked && checked.length && (
        <Fab
          variant="extended"
          className={classes.fabStart}
          color="primary"
          onClick={goToTraining}
        >
          <>
            <PlayCircleOutlineIcon />
            &nbsp; Treinar
            {` ${checked.length} ${checked.length === 1 ? 'tema' : 'temas'}`}
          </>
        </Fab>
      )}
    </>
  );

  return (
    <div className={classes.root}>
      <Header
        icon={TrainingIcon}
        placeholder="Digite para procurar um tema"
        text={filter}
        onChange={filterTags}
      />
      <Title />
      {ready && (
        <>
          {!multiple && hasRecap && RecapLink()}
          {TagLinks()}
          <ActionButton />
        </>
      )}
    </div>
  );
};

export default TrainingTags;

import React from "react";
import ReactDOM from "react-dom";
import "typeface-roboto";
import "./index.css";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import WebFont from "webfontloader";

WebFont.load({
  google: {
    families: ["Roboto", "sans-serif"]
  }
});

Sentry.init({
  dsn:
    "https://d244b53608b4470ba626347a003b9dcb@o881030.ingest.sentry.io/5835289",
  release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Box,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  LinearProgress
} from "@material-ui/core";
import gold from "../../assets/gold.png";
import silver from "../../assets/silver.png";
import bronze from "../../assets/bronze.png";

import httpClient from "../../services/httpClient";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 660,
    margin: "0 auto"
  },
  list: {
    backgroundColor: "#ffffff",
    color: "#333333",
    borderRadius: 20,
    marginLeft: 8,
    marginRight: 8
  },
  title: {
    marginBottom: 20,
    marginLeft: 12,
    fontSize: 14,
    fontWeight: "bold"
  },
  listItem: {
    background: "#CCCCCC"
  },
  name: {
    paddingLeft: 15,
    fontSize: 10,
    "& .MuiListItemText-primary": {
      fontFamily: "VT323",
      textTransform: "uppercase",
      fontSize: 36
    }
  },
  points: {
    paddingLeft: 15,
    fontSize: 10,
    "& .MuiListItemText-primary": {
      fontFamily: "VT323",
      textTransform: "uppercase",
      fontSize: 32,
      color: "#888"
    }
  },
  current: {
    backgroundColor: "yellow",
    borderRadius: 30
  },
  icon: {
    color: "red"
  }
}));

const Ranking = props => {
  const classes = useStyles();
  const [history, setHistory] = useState([]);
  const [week, setWeek] = useState([]);
  const [mock, setMock] = useState([]);
  const [loading, setLoading] = useState(true);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    httpClient.getRanking(props.user.email).then(response => {
      setHistory(response.data.history);
      setWeek(response.data.week);
      setMock(response.data.mock);
      setLoading(false);
    });
  }, [props.user, props.user.mail]);

  const UserList = ({ list }) => {
    if (list && list.length) {
      return list.map((user, index) => {
        return (
          <>
            <ListItem
              key={`userList_${index}`}
              className={user.current ? classes.current : ""}
            >
              <ListItemText
                className={classes.name}
                key={`userListItemRank_${index}`}
                primary={`${user.rank}`}
              />
              <ListItemText
                className={classes.name}
                key={`userListItemName_${index}`}
                primary={`${user.name}`}
              />
              <ListItemText
                className={classes.points}
                key={`userListItemSuccess_${index}`}
                primary={`${user.success}`}
              />
              <ListItemIcon alignItems="center" key={`userItemIcon${index}`}>
                {user.rank === 1 && <img src={gold} alt="1º lugar" />}
                {user.rank === 2 && <img src={silver} alt="2º lugar" />}
                {user.rank === 3 && <img src={bronze} alt="3º lugar" />}
              </ListItemIcon>
            </ListItem>
            <Divider className={classes.listItem} color="#333333" />
          </>
        );
      });
    }
    return <></>;
  };

  const Title = () => {
    return (
      <>
        <Typography className={classes.title}>Ranking</Typography>
        {loading && <LinearProgress variant="query" />}
      </>
    );
  };

  const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Title />
      <AppBar position="static">
        <Tabs value={value} variant="fullWidth" onChange={handleChange}>
          <Tab label="HISTÓRICO" />
          <Tab label="ÚLTIMOS 7 DIAS" />
          <Tab label="SIMULADO" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <List
          className={classes.list}
          component="nav"
          key="rankingListComponent"
        >
          <UserList key="userList" list={history} />
        </List>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <List
          className={classes.list}
          component="nav"
          key="rankingListComponent"
        >
          <UserList key="userList" list={week} />
        </List>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <List
          className={classes.list}
          component="nav"
          key="rankingListComponent"
        >
          <UserList key="userList" list={mock} />
        </List>
      </TabPanel>
      <Divider />
    </div>
  );
};

export default Ranking;
